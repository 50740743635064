require('../../../../../lib/assets/javascripts/cerego_sdk/shared_lib')

# replace these with explicit import
req = require.context('./common/resources', false)
req.keys().forEach(req)
req = require.context('./common/directives', false)
req.keys().forEach(req)
req = require.context('./common/modals', false, /\.js$/)
req.keys().forEach(req)
req = require.context('./common/helpers', false, /\.js$/)
req.keys().forEach(req)
req = require.context('./common/services', false, /\.js$/)
req.keys().forEach(req)

require('./common/filters.js')
require('../../v2/modules/common/filters.js')
require('./common/user-csv-uploader.js')
require('./common/resources/partner-account-resource.ts')
require('../../v2/modules/cerego-toast.js')
require('../../v2/modules/common/time-helper.js')
require('./common/services/react-navigation-service.ts')
