/**
 * This Cerego Shared Library will be shared across iOS, Android, and web front end.
 * Functions that must live in the front end, but we don't want to duplicate in 3 places should go here.
 * The intrasession scheduler is one thing in the future that should be shared in another self contained js file like this.
 * */
CeregoSharedLib = {};

(function() {
  var UNSTARTED_ITEM_BURDEN = 2.0;
  var MAX_BURDEN = 25;
  var FADING_COUNT_CUTOFF = 12;
  var NEW_COUNT_CUTOFF = 3;
  var MAX_NEW_ITEMS = Math.floor(MAX_BURDEN / UNSTARTED_ITEM_BURDEN);

  CeregoSharedLib.studyTypes = {
    FADING: 'fading_only',
    RECOMMENDED: 'recommended'
  };

  /** Given the number of items unstarted, the number of items fading, and the study type for a smart_module,
   * returns the total number of items that v3/my/study_items will recommend be studied in a session.
   * This is used for displaying "Learn 8", or "Review 12" on an assignment card and having it match up with what
   * will be studied, without the need for actually fetching a study session.
   * studyType can be either recommended or fading
   * */
  CeregoSharedLib.sessionSizeParts = function(unstarted, fading, studyType, percentStarted, progress) {
    studyType = studyType || CeregoSharedLib.studyTypes.RECOMMENDED;
    var new_item_count = 0;
    var fading_item_count = 0;

    if (studyType == CeregoSharedLib.studyTypes.RECOMMENDED) {
      if (UNSTARTED_ITEM_BURDEN * unstarted + fading <= MAX_BURDEN) {
        new_item_count = unstarted;
        fading_item_count = fading;
      } else if (percentStarted == progress) {
        new_item_count = Math.min(MAX_NEW_ITEMS, unstarted);
        // NOTE: the math here is a little backwards, but that's because MAX_BURDEN is odd, this prevents us from always
        // padding a random fading item to the end of an all-new session
        fading_item_count = Math.min((MAX_NEW_ITEMS - new_item_count) * UNSTARTED_ITEM_BURDEN, fading);
      } else if (unstarted >= NEW_COUNT_CUTOFF && fading >= FADING_COUNT_CUTOFF) {
        new_item_count = NEW_COUNT_CUTOFF;
        fading_item_count = FADING_COUNT_CUTOFF;
      } else if (unstarted >= NEW_COUNT_CUTOFF && fading < FADING_COUNT_CUTOFF) {
        new_item_count =
          NEW_COUNT_CUTOFF + Math.ceil((MAX_BURDEN - FADING_COUNT_CUTOFF - fading) / UNSTARTED_ITEM_BURDEN);
        fading_item_count = fading;
      } else {
        // implied that unstarted < 3, and fading > 20
        new_item_count = unstarted;
        fading_item_count = FADING_COUNT_CUTOFF + UNSTARTED_ITEM_BURDEN * (NEW_COUNT_CUTOFF - unstarted);
      }
    } else if (studyType == CeregoSharedLib.studyTypes.FADING) {
      new_item_count = 0;
      fading_item_count = Math.min(fading, MAX_BURDEN);
    } else {
      // Will return an empty study session
    }

    // The below shouldn't be necessary, but just in case
    new_item_count = Math.min(new_item_count, unstarted);
    fading_item_count = Math.min(fading_item_count, fading);

    return { unstarted: new_item_count, fading: fading_item_count };
  };

  CeregoSharedLib.sessionSize = function(unstarted, fading, studyType, percentStarted, progress) {
    var parts = CeregoSharedLib.sessionSizeParts(unstarted, fading, studyType, percentStarted, progress);

    return parts.fading + parts.unstarted;
  };
})();
