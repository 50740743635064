ceregoCommon.factory('V3PartnerCoursesResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_course_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
])
