angular.module('cerego.common').component("cgMilestonesList", {
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("milestones_list"),
  bindings: {
    context: '<' # this can be optional later in the case of dashboard
    # Add a thing for previous stuff
  },

  controllerAs: '$milestonesListCtrl',

  controller: ['$filter', '$scope', '$q', 'DebugHelper', 'milestoneService', 'LtiService', ($filter, $scope, $q, DebugHelper, milestoneService, LtiService) ->
    DebugHelper.register("milestonesListCtrl", $scope)

    this.isBBC = LtiService.isBBC
    this.$onInit = () ->
      that = this
      this.assignmentProgress = Math.floor(this.context.meta['memory-aggregate'].progress * 100) || null
      this.assignmentLevel = $filter('levelRoundDown')(this.context.meta["memory-aggregate"]["level"])

      milestoneService.deferred = null
      milestoneService.loadMilestones(this.context).then (milestones) ->
        if _.isNull(that.assignmentProgress)
          milestones = _.reject milestones, (milestone) -> milestone.type == "milestone-set-goal-reacheds"

        that.milestones = milestones

    this # otherwise coffeescript will return the onInit function
  ]
})
