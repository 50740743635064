ceregoCommon.factory('V3ImagesResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_image_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_images_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_image_path(":id")
      params:
        id: "@id"
])
