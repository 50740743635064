ceregoCommon.factory('V3MergeRequestsResource', ['$resource', ($resource) ->
  $resource "", {},
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_merge_request_path(":id")
      params:
        id: "@id"

])
