ceregoCommon.factory('V3UserMetricsExportsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_user_metrics_export_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_user_metrics_exports_path()
])
