import * as angular from 'angular';
import { getPartnerUserRoles, PartnerUserRoleInfo } from 'javascripts/util/partner-user-roles-helper';

interface IControllerScope extends ng.IScope {
  roles: PartnerUserRoleInfo[];
}

angular.module('cerego.common').directive('cgPartnerUserRolesDescriptionsCard', [
  () => ({
    restrict: 'E',
    scope: {},

    templateUrl: Packs.iKnowRoutes.common_v3_templates_path('partner_user_roles_descriptions_card'),

    controller: [
      '$scope',
      'SiteNavigationService',
      ($scope: IControllerScope, SiteNavigationService: any) => {
        $scope.roles = getPartnerUserRoles(SiteNavigationService.isV4Embed());
      }
    ]
  })
]);
