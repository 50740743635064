ceregoCommon.factory('V3AttachedItemsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_item_attached_items_path(":item_id")
      params:
        item_id: "@item_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_item_attached_items_path(":item_id")
      params:
        item_id: "@item_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_item_attached_item_path(":item_id", ":attached_item_id")
      params:
        item_id: "@item_id"
        attached_item_id: "@attached_item_id"
])
