ceregoCommon.directive("cgCourseCard", [ ->
  restrict: "E"
  scope:
    course: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("course_card")

  controller: ['$analytics', '$window', '$scope', '$location', '$filter', 'DebugHelper', 'toastHelper', 'V3CourseUsersResource', 'UserManager', 'StudyItemsService', 'ModalService', ($analytics, $window, $scope, $location, $filter, DebugHelper, toastHelper, V3CourseUsersResource, UserManager, StudyItemsService, ModalService) ->
    DebugHelper.register("cgCourseCard", $scope)

    _.assignIn $scope, {

      stateUI:
        loading: false

      cardClick: () ->
        if $scope.course.meta['is-enrolled']
          $window.location.href = Packs.iKnowRoutes.v3_learn_course_path($scope.course.id)
        else
          ModalService.showModal('course-info-modal', { course: $scope.course })

      unenroll: ($event) ->
        $event.preventDefault()
        $event.stopPropagation()
        V3CourseUsersResource.delete
          course_id: $scope.course.id,
          id: UserManager.currentUser.id
        , (success) ->
          $analytics.eventTrack('self_unenrolled_from_course')
          $scope.course.meta['is-enrolled'] = false
          toastHelper.showToast('simpleToast', message: $filter("translate")("js.learn.courses.course_detail.unenroll_toast", name: $scope.course.attributes.name))
        , (failure) ->
          DebugHelper.logError(failure)

      enroll: ($event) ->
        $event.preventDefault()
        $event.stopPropagation()
        $scope.stateUI.loading = true
        V3CourseUsersResource.create { course_id: $scope.course.id, user_id: UserManager.currentUser.id }
        , (success) ->
          $analytics.eventTrack("self_enrolled_in_course")
          StudyItemsService.loadStudyItem('courses', $scope.course.id, 'recommended', $window.location.pathname).then (studyItem) ->
            $scope.course.meta = success.data.meta
            $scope.stateUI.loading = false
        , (failure) ->
          DebugHelper.logError(failure)
    }
  ]
])
