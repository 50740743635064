ceregoCommon.factory('V3GroupCoursesResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_group_courses_path(":group_id")
      params:
        group_id: "@group_id"
    bulk_destroy:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_group_courses_path(":group_id")
      params:
        group_id: "@group_id"
])
