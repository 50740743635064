ceregoCommon.service('AssignmentDetailsService', ['$filter', '$rootScope', '$route', '$routeParams', '$q', 'DebugHelper', 'V3ModelHelper', 'V3SetsResource', 'V3SeriesResource', 'V3CourseSetsResource', 'CourseContextService', ($filter, $rootScope, $route, $routeParams, $q, DebugHelper, V3ModelHelper, V3SetsResource, V3SeriesResource, V3CourseSetsResource, CourseContextService) ->
  $scope = $rootScope.$new()
  DebugHelper.register("AssignmentDetailsService", $scope)

  _.assignIn $scope,
    assignment: null
    assignmentType: null
    deferred: null

    reset: () ->
      $scope.assignment = null
      $scope.deferred = null

    setDueState: () ->
      if $scope.assignment && $scope.assignment.meta?['due-at']
        $scope.assignment.dueState = "due"
        $scope.assignment.dueDate = $filter("localizeDateWithOutYear")($scope.assignment.meta['due-at'])

        # complete
        if $scope.assignment.meta['memory-aggregate']?['progress'] == 1
          $scope.assignment.dueState = "done"
        # past due
        else if new Date() > new Date($scope.assignment.meta['due-at'])
          $scope.assignment.dueState = "past-due"
        # due within a week, start soon!
        else if $scope.assignment.meta['memory-aggregate'].progress == 0 && (new Date($scope.assignment.meta['due-at']) - new Date() < 604800000)
          $scope.assignment.dueState = "due-soon"
        else if $scope.assignment.meta['memory-aggregate']?['progress'] == 0
          $scope.assignment.dueState = "start-soon"


    initialize: () ->
      $scope.assignmentType = $route.current.locals.$assignment_type || "set"
      unless $scope.deferred
        $scope.deferred = $q.defer()

        assignment_id = $routeParams.set_id || $routeParams.setId || $routeParams.series_id || $routeParams.seriesId || $routeParams.id
        courseId = CourseContextService.getCourseId()
        if assignment_id
          if $scope.assignmentType == "series"
            resource = V3SeriesResource
          else if courseId != -1
            resource = V3CourseSetsResource
          else
            resource = V3SetsResource
          resource.get
            id: assignment_id
            course_id: courseId
            include: "creator,image,creator.logo-image,creator.image"
          , (success) ->
            success = V3ModelHelper.parse(success)
            $scope.assignment = success
            $scope.setDueState()
            $scope.deferred.resolve($scope.assignment)
          , $scope.deferred.reject
        else
          $scope.deferred.reject()
      $scope.deferred.promise


  $scope
])
