ceregoCommon.directive('progressIndicatorOrbs', ['$timeout', ($timeout) ->
  scope:
    progressIndicatorOrbs: "@"
    orbs: "="
    isActive: "="
    isComplete: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("_progress_indicator_orbs")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("progressIndicatorOrbs", $scope)
  ]
])
