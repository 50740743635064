ceregoCommon.factory('V3GroupUsersResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_group_users_path(":group_id")
      params:
        group_id: "@group_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_group_users_path(":group_id")
      params:
        group_id: "@group_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_group_user_path(":group_id", ":id")
      params:
        group_id: "@group_id"
        id: "@id"
    bulk_destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_group_users_path(":group_id")
      params:
        group_id: "@group_id"
])
