angular.module('cerego.common').factory('V3VideosResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) =>
    $resource(
      '',
      {},
      {
        transcription: {
          method: 'POST',
          isArray: false,
          url: APIRoutes.transcription_v3_video_path(':id'),
          params: {
            id: '@id'
          }
        }
      }
    )
]);
