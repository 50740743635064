ceregoCommon.service('milestoneService', ['$rootScope', '$q', 'DebugHelper', 'iFrameMessageService', 'V3MyMilestonesResource', 'V3ModelHelper', ($rootScope, $q, DebugHelper, iFrameMessageService, V3MyMilestonesResource, V3ModelHelper) ->

  $scope = $rootScope.$new()
  DebugHelper.register("milestoneService", $scope)

  _.assignIn $scope,
    previousMilestones: []
    currentModule: {}

    checkNewBadgeUnlocked: () ->
      if $scope.previousMilestones.length > 0
        _.each $scope.milestones, (milestone, index) ->
          milestone.attributes["first-time-unlocked"] = (milestone.attributes["reporting-type"] == "boolean" &&
                                        milestone.attributes.value &&
                                        !$scope.previousMilestones[index].attributes.value) ||
                                        (milestone.attributes["reporting-type"] == "percentage" &&
                                        milestone.attributes.value == 1 &&
                                        $scope.previousMilestones[index].attributes.value < 1)
          if milestone.attributes["first-time-unlocked"]
            iFrameMessageService.postMessage({
              messageType: "milestone-unlocked",
              context: {type: $scope.currentModule.type, name: $scope.currentModule.name || $scope.currentModule.attributes.name, id: $scope.currentModule.id},
              data: { milestoneType: milestone.type, value: milestone.attributes.value }
            })

    loadMilestones: (module) ->
      if $scope.currentModule.id != module.id || $scope.currentModule.type != module.type
        $scope.currentModule = module
        $scope.previousMilestones = []

      params = {}
      switch module.type
        when "sets"
          params['set_id'] = module.id
        when "series"
          params['series_id'] = module.id
        when "courses"
          params['course_id'] = module.id

      unless $scope.deferred
        $scope.deferred = $q.defer()

        V3MyMilestonesResource.query params
          , (success) ->
            $scope.milestones = V3ModelHelper.parse(success)
            $scope.checkNewBadgeUnlocked()
            $scope.previousMilestones = $scope.milestones

            $scope.deferred.resolve($scope.milestones)
          , (error) ->
            $scope.deferred.reject(error)
      $scope.deferred.promise

  $scope
])
