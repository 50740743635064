# This service manages some shared logic for the CreateSmartGroupModal and EditSmartGroupModal. It is separate from SmartGroupRuleHelper
# because it manages some state that is accessed by both CreateSmartGroupModal and EditSmartGroupModal,
# whereas SmartGroupRuleHelper only has pure functions and could be used on other pages than the 2 smart group modals
ceregoCommon.service("SmartGroupRuleEditorService", ['$rootScope', '$filter', '$routeParams', 'DebugHelper', 'V3GroupsResource', 'V3ModelHelper', (
    $rootScope, $filter, $routeParams, DebugHelper, V3GroupsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("SmartGroupRuleEditorService", $scope)

  _.assignIn $scope,
    uiState: {}

    comparators: [
      $filter("translate")("js.partners.groups.rules.comparators.is"),
      $filter("translate")("js.partners.groups.rules.comparators.is_not")
    ]
    rules: [{}]

    initialize: (rules) ->
      $scope.uiState.validRules = false               # flag used to prevent saving group if rules are invalid
      $scope.uiState.beforePreview = true             # true until preview has been called once
      $scope.uiState.pendingPreview = false           # flag used to show spinner over preview card while preview is being generated
      $scope.uiState.previewMembersHeaderString = ""  # string with count of previewed users
      $scope.uiState.previewMembersString = ""        # concatenated list of previewable users
      if rules
        $scope.rules = rules
      else
        $scope.rules = [{}]

    validateAllRules: ->
      $scope.uiState.validRules = if $scope.rules.length == 0
        false
      else
        _.every($scope.rules, (rule) ->
          _.has(rule, "tag_type_label") && !_.isEmpty(rule.tag_type_label)
        )

    addRule: ->
      $scope.rules.push({})
      $scope.uiState.validRules = false # new rule is always invalid

    deleteRule: (index) ->
      $scope.rules.splice(index, 1)
      $scope.validateAllRules()
      $scope.debouncedPreviewGroupMembers()

    ruleAsJson: (rule) ->
      comparatorSymbol = if _.isEqual(rule.comparator, $filter("translate")("js.partners.groups.rules.comparators.is")) then "=" else "!="
      json = {}
      json[comparatorSymbol] =
        tag_type_id: rule.tag_type_id
        tag_type_label: rule.tag_type_label # doesn't actually get used by backend. for debugging purposes
        value: rule.value
      json

    previewGroupMembers: ->
      $scope.validateAllRules()
      $scope.uiState.beforePreview = false
      $scope.uiState.pendingPreview = true
      rules_json = JSON.stringify({ "AND": _.map($scope.rules, (rule) -> $scope.ruleAsJson(rule)) }) # hardcode AND until we support other rules
      V3GroupsResource.smart_group_preview { partner_id: $routeParams.partner_id },
        rules_json: rules_json,
        include: "user_partner_id"
      , (success) ->
        previewMembersCount = success.data.length
        if previewMembersCount >= 5
          $scope.uiState.previewMembersHeaderString = $filter("translate")("js.partners.groups.create_smart_modal.preview.at_least", count: previewMembersCount)
        else
          $scope.uiState.previewMembersHeaderString = $filter("translate")("js.partners.groups.create_smart_modal.preview.exact", count: previewMembersCount)
        users = V3ModelHelper.parse(success)
        # TODO stretch goal - add link to user profile here once that page exists
        userStrings = _.map(success.data, (user) ->
          user.attributes.email || user.relationships["user-partner-id"].data.attributes["member-id"]
        )
        $scope.uiState.previewMembersString = _.join(userStrings, ", ")
        $scope.uiState.pendingPreview = false

    buildRulesJson: ->
      JSON.stringify({ "AND": _.map($scope.rules, (rule) -> $scope.ruleAsJson(rule)) }) # hardcode AND until we support other rules

  $scope.debouncedPreviewGroupMembers = _.debounce($scope.previewGroupMembers, 1000, { trailing: true })
  $scope
])
