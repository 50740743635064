ceregoCommon.service("V3VocabHighlightingService", [ 'SentenceConverter', (SentenceConverter)->
  service =
    insertBlankInSentence: (sentence) ->
      firstSentenceText = SentenceConverter.html2markup(sentence.attributes['text-html'])
      filterStars = /\*.*\*/
      blanks = "______"
      firstSentenceText.replace(filterStars, blanks)

  service
])
