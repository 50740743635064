export interface PartnerUserRoleInfo {
  role: string;
  ability: string;
  role_name: string;
}

export function getPartnerUserRoles(isNewNav: boolean): PartnerUserRoleInfo[] {
  return [
    {
      role: 'js.partners.roles.admin',
      ability: 'js.partners.abilities.admin',
      role_name: 'admin'
    },
    {
      role: 'js.partners.roles.course_manager',
      ability: 'js.partners.abilities.course_manager',
      role_name: 'course_manager'
    },
    {
      role: 'js.partners.roles.content_manager',
      ability: 'js.partners.abilities.content_manager',
      role_name: 'content_manager'
    },
    {
      role: 'js.partners.roles.course_viewer',
      ability: 'js.partners.abilities.course_viewer',
      role_name: 'course_viewer'
    },
    {
      role: 'js.partners.roles.editor',
      ability: 'js.partners.abilities.editor',
      role_name: 'editor'
    }
  ].filter((roleInfo: PartnerUserRoleInfo) => {
    if (isNewNav) {
      return roleInfo.role_name != 'editor';
    } else {
      return roleInfo.role_name != 'course_viewer';
    }
  });
}
