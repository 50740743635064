ceregoCommon.directive("cgSmartLearnCard", [ ->
  restrict: "E"
  scope:
    contextType: "="
    contextId: "="
    fromEmbed: "=?"

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("smart_learn_card")

  controller: ['$window', '$scope', '$location', 'DebugHelper', 'UserManager', 'V3ModelHelper', 'V4RecommendedSetsResource', 'setLearnURLService',
  ($window, $scope, $location, DebugHelper, UserManager, V3ModelHelper, V4RecommendedSetsResource, setLearnURLService) ->

    DebugHelper.register("cgSmartLearnCard", $scope)

    _.assignIn $scope, {
      stateUI:
        loaded: false
      recommendedTab:
        slug: 'recommended'
        valid: false
        loaded: false
        buttonClass: 'v3-button button-large'
        studyItem: {}
        scormPackageId: ""
      goodForNowTab:
        slug: 'good_for_now'
        valid: false
        loaded: false
        studyItem: {}
        scormPackageId: ""
      fadingTab:
        slug: 'fading'
        valid: false
        loaded: false
        buttonClass: 'v3-secondary-button button-large'
        studyItem: {}
        scormPackageId: ""

      showRecommendedTab: () ->
        $scope.showTab($scope.recommendedTab)

      showFadingTab: () ->
        if $scope.fadingTab.loaded
          $scope.showTab($scope.fadingTab)

      # Set tab info to display
      showTab: (tab) ->
        $scope.stateUI.activeTab = tab

      loadCard: () ->
        params = { include_scorm: true }
        
        switch $scope.contextType
          when 'sets' then params.set_id = $scope.contextId
          when 'series' then params.series_id = $scope.contextId
          when 'courses' then params.course_id = $scope.contextId

        V4RecommendedSetsResource.query params
        , (success) ->
          parsedSuccess = V3ModelHelper.parse(success)
          _.each parsedSuccess, (tab) => 
            switch tab.attributes['study-type']
              when 'recommended' then tabScope = 'recommendedTab'
              when 'fading_only' then tabScope = 'fadingTab'

            setID = tab.relationships.set.data.id
            setAttrs = tab.relationships.set.data.attributes
            
            Object.assign($scope[tabScope], {
              loaded: true,
              valid: true,
              studyItem: {
                skipModal: true
                scormPackageId: setAttrs['scorm-package-id']
                buttonText: tab.attributes.cta || 'Learn now!'
                learnPath: setLearnURLService.getUrl({id: parseInt(setID), version: setAttrs['learn-version']}, { studyType: tab.attributes['study-type'] })
                bodyTextHtml: tab.attributes.description
              }
            })
          
          if success.data.length == 0
            $scope.recommendedTab.loaded = true
            $scope.goodForNowTab.loaded = true
            $scope.goodForNowTab.valid = true
            Object.assign($scope.goodForNowTab.studyItem, {
              bodyTextHtml: success.meta['overall-description']
            })
            $scope.showTab($scope.goodForNowTab)
          else
            $scope.showTab($scope.recommendedTab)
    }

    $scope.loadCard()
    $scope.$on "scorm:launchAttempt", () ->
      $scope.loadCard() # reload card and get next module on scorm launch

  ]
])
