ceregoCommon.factory('V3CourseGroupsResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_course_groups_path(":course_id")
      params:
        course_id: "@course_id"
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_groups_path(":course_id")
      params:
        course_id: "@course_id"
    destroy:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_course_group_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    bulk_destroy:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_course_groups_path(":course_id")
      params:
        course_id: "@course_id"

])
