ceregoCommon.controller("editQuizModal", ['$scope', '$filter', 'V3VocabHighlightingService', 'V3PassageHighlightingService', 'V3SmartAutoSaveService', 'V3TransliterationDistractorsResource', 'QuizNameService', 'V3ModelHelper', 'V3ConceptsResource', 'V3ConceptDistractorsResource', 'V3ContentService', 'V3RegionContentService', 'V3RegionsService', 'CombiningMarksService', 'SentenceConverter', 'DebugHelper', ($scope, $filter, V3VocabHighlightingService, V3PassageHighlightingService, V3SmartAutoSaveService, V3TransliterationDistractorsResource, QuizNameService, V3ModelHelper, V3ConceptsResource, V3ConceptDistractorsResource, V3ContentService, V3RegionContentService, V3RegionsService, CombiningMarksService, SentenceConverter, DebugHelper) ->
  DebugHelper.register("editQuizModal", $scope)

  $scope.transliterationDistractors = []

  _.assignIn $scope,
    V3ContentService: V3ContentService
    V3SmartAutoSaveService: V3SmartAutoSaveService
    QuizNameService: QuizNameService

    answer: ''
    manualDistractors: []

    IMAGE_DISTRACTORS_COUNT: 5
    TEXT_DISTRACTORS_COUNT: 4
    SEQUENCE_DISTRACTOR_COUNT: 3

    QUESTION_REGION_MAX_HEIGHT: 250
    ANSWER_REGION_MAX_HEIGHT: 200

    VOCAB_QUIZ_SPACES: 5

    stateUI:
      imageQuiz: false
      autogenDistractorsAllowed: false

    templateTypes:
      'fill_in_the_blank':
        iconClass: 'fill-in-the-blank'
      'multiple_choice':
        iconClass: 'multiple-choice'
      'sequence_injection':
        iconClass: 'sequence-injection'
      'click_in_the_dark':
        iconClass: 'click-in-the-dark'

    cardSides:
      'anchor':
        dataModel: $scope.modalOptions.model.relationships.anchor.data
        distractors: $scope.modalOptions.model.relationships.anchor.data?.relationships?.distractors?.data
      'association':
        dataModel: $scope.modalOptions.model.relationships.association.data
        distractors: $scope.modalOptions.model.relationships.association.data?.relationships?.distractors?.data
      'sequence_node':
        dataModel: $scope.modalOptions.model.relationships.association.data
        distractors: $scope.modalOptions.model.relationships.association.data?.relationships?.distractors?.data
      'transliteration':
        dataModel: $scope.modalOptions.model.relationships.association.data
        distractors: $scope.transliterationDistractors
      'anchor_cloze':
        dataModel: $scope.modalOptions.model.relationships.anchor.data
        distractors: $scope.modalOptions.model.relationships.anchor.data?.relationships?.distractors?.data
      'sentence':
        dataModel: $scope.modalOptions.model.relationships.anchor.data
        distractors: $scope.modalOptions.model.relationships.anchor.data?.relationships?.distractors?.data
      'sentence_audio':
        dataModel: $scope.modalOptions.model.relationships.association.data
      'anchor_audio':
        dataModel: $scope.modalOptions.model.relationships.association.data
        distractors: $scope.modalOptions.model.relationships.association.data?.relationships?.distractors?.data

    toggleNota: () ->
      $scope.setImageQuiz()
      $scope.setAutogenDistractorsAllowed()

    setAutogenDistractorsAllowed: ->
      $scope.setImageQuiz()
      if $scope.stateUI.imageQuiz
        distractorsRequired = $scope.IMAGE_DISTRACTORS_COUNT
      else
        distractorsRequired = $scope.TEXT_DISTRACTORS_COUNT
      distractorsRequired -= $scope.manualDistractors.length
      $scope.stateUI.autogenDistractorsAllowed = distractorsRequired <= 0
      unless $scope.stateUI.autogenDistractorsAllowed
        $scope.answerSide?.attributes['allow-random-distractors'] = true

    hasNOTA: ->
      $scope.modalOptions.model.attributes['nota-settings'][QuizNameService.quizzes[$scope.modalOptions.template].answer]

    questionHasQuestionMark: ->
      !_.includes(['sentence', 'anchor_cloze'], QuizNameService.quizzes[$scope.modalOptions.template].question)

    quizQuestion: ->
      if $scope.modalOptions.template == 'reverse_transliteration_multiple_choice_limited'
        $scope.modalOptions.model.attributes['transliteration-html']
      else if QuizNameService.quizzes[$scope.modalOptions.template].question == 'anchor_cloze'
        V3PassageHighlightingService.insertBlankInPassage($scope.questionSide)
      else if QuizNameService.quizzes[$scope.modalOptions.template].question == 'sentence'
        # Find the first sentence that has text & convert the term to underscores
        firstSentence = _.filter(V3ContentService.item.relationships.sentences.data, (sentence) -> sentence.attributes['text-html']?)[0]
        V3VocabHighlightingService.insertBlankInSentence(firstSentence)
      else
        $scope.questionSide.attributes['text-html']

    fibContent: ->
      return if !$scope.answer

      answer = $filter('stripHTML')($scope.answer)
      answer = CombiningMarksService.stripMarks(answer)
        .replace /(.)/g, (char) ->
          if char.match /[\s\#]/
            "<space>" + char + "</space>"
          else
            "<letter />"
      answer

    killDistractor: (distractor) ->
      _.pull($scope.distractors, distractor)
      _.pull($scope.manualDistractors, distractor)
      $scope.setAutogenDistractorsAllowed()
      $scope.setImageQuiz()

    showImageQuiz: ->
      QuizNameService.quizzes[$scope.modalOptions.template].type == 'multiple_choice' &&
        $scope.modalOptions.template != 'region_reverse_multiple_choice' &&
        $scope.answerSide.relationships.image.data

    showImageAsQuestion: ->
      V3ContentService.item.attributes['template-type'] == 'regions' &&
        ($scope.modalOptions.template == 'multiple_choice_limited' ||
          $scope.modalOptions.template == 'fill_in_the_blank')

    removeDistractor: (distractor) ->
      if QuizNameService.quizzes[$scope.modalOptions.template].distractor == 'transliteration'
        V3TransliterationDistractorsResource.delete
          id: distractor.id
        , (success) ->
          $scope.killDistractor(distractor)
        , (error) ->
            DebugHelper.logError(error, "V3TransliterationDistractorResource Delete Error, id: " + distractor.id)
      else
        V3ConceptsResource.delete
          id: distractor.id
        , (success) ->
          $scope.killDistractor(distractor)
        , (error) ->
          DebugHelper.logError(error, "V3ConceptsResource Delete Error, id: " + distractor.id)

    setSaveForAttributes: (distractor) ->
      # If you add or remove an image the quiz type can change from text to image or vice versa
      $scope.$watch "manualDistractors", (newVal, oldVal) ->
        if newVal != oldVal
          $scope.setImageQuiz()
          $scope.setAutogenDistractorsAllowed()
      , true
      V3SmartAutoSaveService.registerSaveItem(distractor, "attributes['text-html']")
      V3SmartAutoSaveService.registerSaveItem(distractor, "relationships.image.data.id")

    sentenceAudio: ->
      _.includes(['sentence_audio', 'anchor_audio'], QuizNameService.quizzes[$scope.modalOptions.template].question)

    saveNewDistractor: (distractor) ->
      distractor.attributes['is-manual'] = true
      $scope.setSaveForAttributes(distractor)
      $scope.distractors.splice($scope.manualDistractors.length, 0, distractor)
      $scope.manualDistractors.push(distractor)
      $scope.setAutogenDistractorsAllowed()

    addDistractor: (distractors) ->
      if QuizNameService.quizzes[$scope.modalOptions.template].distractor == 'transliteration'
        V3TransliterationDistractorsResource.create
          facet_id: V3ContentService.facets[0].id
        , (success) ->
          $scope.saveNewDistractor(V3ModelHelper.parse(success))
        , (error) ->
          console.error error
      else
        V3ConceptDistractorsResource.create
          concept_id: $scope.answerSide.id
        , (success) ->
          $scope.saveNewDistractor(V3ModelHelper.parse(success))
        , (error) ->
          console.error error

    setImageQuiz: ->
      distractorAmount = $scope.TEXT_DISTRACTORS_COUNT # If the 5th distractor is the only image, do we want to display an image quiz? Right now it will not
      if $scope.hasNOTA()
        distractorAmount -= 1
      $scope.stateUI.imageQuiz = _.some($scope.answerSide?.relationships?.distractors?.data.slice(0, distractorAmount), (distractor) -> distractor.relationships?.image.data?) || $scope.answerSide?.relationships?.image.data?

    saveDistractors: ->
      for distractor in $scope.manualDistractors
        $scope.setSaveForAttributes(distractor)

    initializeSVG: (regionName, regionStyle) ->
      $scope.svg = d3.selectAll(regionName + " svg")
      $scope.svg[0] = $scope.svg[0].slice($scope.svg[0].length - 1)
      # A better way to do this would be to loop through all the $scope.svg[0]'s, and find the one that matches the current element
      # However, this way should always work and not break in the case the html is changed in the view
      $scope.updateRegions(regionStyle)

    updateRegions: (regionStyle) ->
      polygonData = V3RegionsService.polygons($scope.regionHighlight)

      polygons = $scope.svg.selectAll(".editableRegion").data(polygonData, (d) -> d.points)
      polygons.enter().append("polygon").attr
        class: "editableRegion region " + regionStyle
        points: (d) -> d.points
      .style
        filter: "url(#blur)"
      polygons.exit().remove()

    initialize: () ->

      $scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].answer].distractors ||= []
      _.set($scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].answer].dataModel,
        'relationships.distractors.data',
        $scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].answer].distractors)
      # If the type of distractor is transliteration then the answer should also be a transliteration, the transliteration can be the first one I find within the list of sentences which should always exist
      $scope.sentences = V3ContentService.item.relationships.sentences
      $scope.questionSide = $scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].question]?.dataModel
      $scope.answerSide = $scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].answer]?.dataModel
      $scope.distractors = $scope.cardSides[QuizNameService.quizzes[$scope.modalOptions.template].answer].distractors
      $scope.answer = $scope.answerSide?.attributes?['text-html']
      if QuizNameService.quizzes[$scope.modalOptions.template].answer == 'transliteration'
        $scope.answer = V3ContentService.facets[0].attributes['transliteration-html']

      if V3ContentService.item.attributes['template-type'] == 'regions'
        $scope.regionHighlight = V3RegionContentService.createRegionHighlight($scope)
        $scope.regionsToShow = [$scope.modalOptions.model]

      if QuizNameService.quizzes[$scope.modalOptions.template].distractor == 'transliteration'
        V3TransliterationDistractorsResource.query { facet_id: V3ContentService.facets[0].id }
        , (success) ->
          $scope.transliterationDistractors = success.data
          $scope.manualDistractors = _.filter($scope.transliterationDistractors, (t) -> t.attributes['is-manual'])
          $scope.saveDistractors()
          $scope.distractors = $scope.manualDistractors.concat(V3ContentService.facets[0].relationships.association.data.relationships.distractors?.data)
          $scope.setImageQuiz()
          $scope.setAutogenDistractorsAllowed()
        , (error) ->
          DebugHelper.logError(error, "TransliterationGetError")
      # xSide is going to be either the association or the anchor depending on the template type

      if QuizNameService.quizzes[$scope.modalOptions.template].answer == 'anchor' || QuizNameService.quizzes[$scope.modalOptions.template].answer == 'association'
        $scope.manualDistractors = _.filter($scope.answerSide?.relationships.distractors?.data, (obj) ->
          obj.attributes['is-manual']
        )
      else if QuizNameService.quizzes[$scope.modalOptions.template].distractor != 'transliteration'
        $scope.manualDistractors = $scope.transliterationDistractors
      $scope.saveDistractors()

      answerSideSlug = QuizNameService.quizzes[$scope.modalOptions.template].answer
      V3SmartAutoSaveService.registerSaveItem($scope.modalOptions.model, "attributes['nota-settings']['" + answerSideSlug + "']")
      V3SmartAutoSaveService.registerSaveItem($scope.answerSide, "attributes['allow-random-distractors']") if $scope.answerSide
      V3SmartAutoSaveService.registerSaveItem($scope.modalOptions.model, "attributes['anchor-question-html']")
      V3SmartAutoSaveService.registerSaveItem($scope.modalOptions.model, "attributes['association-question-html']")

      $scope.setImageQuiz()
      $scope.setAutogenDistractorsAllowed()

  $scope.initialize()
])
