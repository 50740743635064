ceregoCommon.factory('PartnerDomainsResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_partner_domains_path(":partner_id")
      params:
        partner_id: "@partner_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_partner_domains_path(":partner_id")
      params:
        partner_id: "@partner_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_partner_domain_path(":id")
      params:
        id: "@id"
    remove:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_domain_path(":id")
      params:
        id: "@id"

])
