ceregoCommon.directive('miniMemoryBank', [ '$timeout', ($timeout) ->
  restrict : "E"

  scope:
    showModal:          "="
    context:            "=?"
    course:             "=?"
    module:             "=?"
    lastStudyTime:      "=?"

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("mini_memory_bank")

  controller: ['$scope', '$q', 'MemoryBankLevelsService', '$window', '$filter', '$routeParams', 'progressVisual', 'MemoryBankAssetHelper', 'MemoryStorage', 'UserManager', 'DebugHelper', (
      $scope, $q, MemoryBankLevelsService, $window, $filter, $routeParams, progressVisual, MemoryBankAssetHelper, MemoryStorage, UserManager, DebugHelper) ->
    DebugHelper.register("miniMemoryBankCtrl", $scope)


    _.assignIn $scope,
      MODULE_RADIUS: 30
      WELCOME: 1
      WELCOME_BACK: 2
      ENCOURAGING: 3
      levels: _.drop(MemoryBankLevelsService.levels, 1)
      forMobile: angular.element($window).width() < 768
      preloadedOrbs: []

      prepareFacet: (facet, radius) ->
        facet.radius = radius
        facet.orb_type = "facet"
        facet.level_slug = MemoryBankLevelsService.binContinuousLevel(facet.level, facet.review_interval).slug
        true

      prepareModule: (module, radius) ->
        module.radius = radius
        module.orb_type = "module"
        module.level_slug = MemoryBankLevelsService.slugLevel(module.score)
        module.image ||= MemoryBankAssetHelper.defaultModuleOrb()
        true

      tick: (selection) ->
        # Reset tick counters
        tick = 0
        $scope.force.on "start", ->
          tick = 0

        selection.each (d) ->
          d.startTick = Math.round(Math.random()*12)

        # Actual tick function
        (e) ->
          alpha = e.alpha
          k = .4
          wiggle = .2
          tick += 1

          selection.each (node) ->
            # move closer to target with a little wiggle to prevent local minima (aka local gravity)
            if tick > node.startTick && node.weight == 0
              node.x += (node.target.x - node.x)*k*alpha
              node.y += (node.target.y - node.y)*k*alpha

            node.y = Math.max($scope.bb.top, Math.min(node.y, $scope.bb.bottom))

          # Finally, update the dom
          selection.call($scope.moveToPosition)

      radius: (orb_type) ->
        switch orb_type
          when "module"
            $scope.MODULE_RADIUS
          else
            8

      updateSVG: (data) ->
        LEFT_BORDER_WIDTH = 0
        RIGHT_BORDER_WIDTH = 0
        HEADER_HEIGHT = 0
        NEEDS_REVIEW_RETENTION = .85
        if angular.element($window).width() > 768
          $scope.totalLevels = MemoryBankLevelsService.totalLevelsExcludeUnstarted
        else if $scope.module
          $scope.totalLevels = _.max([$scope.levels.length, $scope.module.scoring_goal + 2])
        else
          $scope.totalLevels = $scope.levels.length

        radius = $scope.radius($scope.orb_type)

        lockedLevelSize = if angular.element($window).width() > 768 then 65 else 40

        nextLockedLevel = Math.ceil(_.max(_.map(data, (d) -> d.score || d.level)) || 0.3)
        $scope.unlockedLevels = _.filter($scope.levels, (level) -> level.minLevel < nextLockedLevel)

        svgDimensions =
          x: angular.element(".mini-memory-bank").width() - (($scope.totalLevels - $scope.unlockedLevels.length) * lockedLevelSize)
          y: angular.element(".mini-memory-bank").height()

        $scope.bb =
          left: LEFT_BORDER_WIDTH + radius
          right: svgDimensions.x - RIGHT_BORDER_WIDTH - radius
          top: HEADER_HEIGHT + radius + 1
          bottom: svgDimensions.y - radius - 1

        for orb in data
          if $scope.orb_type == "module"
            $scope.prepareModule(orb, radius)
          else
            $scope.prepareFacet(orb, radius)

        domain = _.map($scope.unlockedLevels, "minLevel").concat(nextLockedLevel)
        range = [$scope.bb.left]
        _.times $scope.unlockedLevels.length, (i) ->
          range.push($scope.bb.left + ($scope.bb.right - $scope.bb.left) * (i + 1) / $scope.unlockedLevels.length)

        $scope.xScale = d3.scale.linear().domain(domain).range(range)
        $scope.yScale = d3.scale.linear().domain([1,NEEDS_REVIEW_RETENTION,0]).range([$scope.bb.top, .5 * ($scope.bb.bottom + $scope.bb.top), $scope.bb.bottom])

        selection = $scope.svg.selectAll(".progress-orb")

        keyFn = (d) ->
          if $scope.orb_type == "facet"
            $scope.orb_type + ":" + d.learning_engine_guid
          else
            $scope.orb_type + ":" + d.id
        selection = selection.data(data, keyFn)

        # Create new nodes
        selection.enter().append("g").call($scope.createOrb)

        # Set up clipPath we can use to clip module images to a circle
        # N.B. putting this clipPath in the link function (like in the main knowledge bank) was leading to
        # the clipPath not being applied properly during a soft load of the mini knowledge bank
        defs = $scope.svg.append("defs")
        defs.append "clipPath"
          .attr
            id: "miniMbModuleClipPath"
          .append "circle"
          .attr
            cx: 0
            cy: 0
            r: $scope.MODULE_RADIUS - 4

        # Rebuild force layout
        if $scope.force
          $scope.force.stop()

        $scope.force = d3.layout.force()
          .nodes(_.filter(selection.data(), (d) -> !_.isUndefined(d)))
          .gravity(0)
          .charge(0)
          .friction(.6)
          .charge (d) ->
            if d.weight > 0
              -20 * d.radius
            else
              0
          .chargeDistance(100)
          .size [svgDimensions.x, svgDimensions.y]

        $scope.force.on "tick", $scope.tick(selection)

        # Calculate theoretical final position
        selection.call($scope.resetAnimation).call($scope.setTargetPosition)

        # Perform animating simulation with timers between ticks
        console.log "STARTING ANIMATION"
        selection.call($scope.doAnimate)

      doAnimate: (selection) ->
        $scope.force.start() # need to use start and not resume in order to rebuild internal link strengths
        selection.classed
          background: false
          selected: (d) -> d.weight > 0
        if $scope.force.links().length > 0
          selection.classed
            background: (d) -> d.weight == 0

      calculateFinalPosition: (d) ->
        x: $scope.xScale(d.score || d.level)
        y: $scope.yScale($scope.orbRetention(d))

      setTargetPosition: (selection) ->
        selection.each (d) ->
          finalPosition = $scope.calculateFinalPosition(d)
          d.target =
            x: finalPosition.x
            y: finalPosition.y

      orbRetention: (d) ->
        if $scope.orb_type == "facet" then d.current_retention else d.average_current_retention

      # sets the position of the orb at the beginning of the animation
      setSourcePosition: (preload) ->
        (selection) ->
          height = angular.element(".mini-memory-bank").height()
          selection.each (d) ->
            sourceOrb = _.find($scope.preloadedOrbs, (orb) -> orb.learning_engine_guid == d.learning_engine_guid)
            if $scope.preload && sourceOrb
              sourcePosition = $scope.calculateFinalPosition(sourceOrb)
              d.x = sourcePosition.x
              d.y = sourcePosition.y
            else
              # otherwise, recent new memories animate from lower left
              d.x = Math.random() * -100
              d.y = height - (Math.random() * height/4)

      moveToPosition: (selection) ->
        selection.attr
          transform: (d) ->
            "translate(" + [d.x, d.y] + ")"

      resetAnimation: (selection) ->
        $scope.force.links([])
        selection.each (d) ->
          d.px = d.x
          d.py = d.y
          d.fixed = false

      # Called for new nodes
      createOrb: (selection) ->
        # Attach level_slug first so we can do it programmatically without doing string append to the class
        selection.attr("class", (d) -> d.level_slug)
        selection.classed
          "progress-orb":    true
          "progress-module": (d) -> d.orb_type == "module"
          "progress-facet":  (d) -> d.orb_type == "facet"
        .call($scope.setSourcePosition($scope.preload))
        .call($scope.moveToPosition)
        .attr
          module_id: (d) -> d.id if d.orb_type == "module"

        selection.append "circle"
          .attr
            cx: 0
            cy: 0
            r: (d) -> d.radius

        selection.filter (d) ->
          d.orb_type != "facet"
        .each (d) ->
          d3.select(this).append "image"
          .attr
            "xlink:href": (d) ->
              $filter("transcode")(d.image, 52)
            "clip-path": (d) ->
              "url(#miniMbModuleClipPath)"
            preserveAspectRatio: "xMidYMid slice"
            x: -d.radius + 4
            y: -d.radius + 4
            width: (d.radius - 4)*2
            height: (d.radius - 4)*2
            title: (d) -> d.name

      loadData: (constraints) ->
        UserManager.loadUser().then (user) ->
          constraints.user_id = user.id
          $scope.memoryBankModalOptions.user = user
          lastConstraints = MemoryStorage.getLastConstraints()

          # if current context is the exact same as last, load from cache + dont animate
          if _.isEqual(lastConstraints, constraints)
            $scope.preload = true
            preloadConstraints = constraints
          # if we just studied the same set we loaded previously,
          # draw previous cache then animate the orbs up
          else if _.isEqual(_.omit(lastConstraints, "lastStudyTime"), _.omit(constraints, "lastStudyTime"))
            $scope.preload = true
            preloadConstraints = lastConstraints
          # if current context is totally different than the last one, redraw from scratch
          else
            $scope.preload = false

          if $scope.preload
            # on preload, load the memory data of where the orbs should start
            $scope.loadMemoryStorageData(preloadConstraints).then () ->
              # save initial starting positions
              $scope.preloadedOrbs = $scope.orbs
              # draw the orbs based on final position constraints
              $scope.drawData(constraints)
          else
            # on no preload, load data once + draw
            $scope.drawData(constraints)


      loadMemoryStorageData: (constraints) ->
        deferred = $q.defer()
        MemoryStorage.load(constraints).then (response) ->
          $scope.memoriesLength = response.memories.length
          $scope.memoryBankModalOptions.showLearnButton = $scope.memoriesLength > 0
          # filter out unstarted orbs
          $scope.orbs = _.cloneDeep(_.filter(response.memories, (m) ->
            m.level > 0))

          deferred.resolve()
        , (error) ->
          deferred.reject()
          $scope.error = (error.data && error.data.meta && error.data.meta.message) || $filter('translate')("js.memory_bank.could_not_load")

      drawData: (constraints) ->
        $scope.loadMemoryStorageData(constraints).then ->
          # if we changed memories, reset memories after load
          if $scope.preload && $scope.preloadedOrbs != $scope.orbs
            MemoryStorage.reset()
          $timeout ->
            $scope.updateSVG($scope.orbs)

      prepareMiniMemoryBank: (constraints) ->
        promise = $scope.loadData(constraints)
  ]

  link: ($scope, element) ->
    $scope.svg = d3.select(element[0].querySelector('svg'))

    # TODO use directive inputs to set group, module_type, module_id in constraints passed to loadData
    constraints = {}
    $scope.memoryBankModalOptions = {}

    # after study, animate orbs. only reanimate if lastStudyTime has changed
    if $scope.lastStudyTime
      constraints.lastStudyTime = $scope.lastStudyTime

    if $scope.context == "course"
      constraints.group_id = $scope.course.id
      $scope.orb_type = "module"
      $scope.memoryBankModalOptions.course = $scope.course
      $scope.memoryBankModalOptions.forceAssignmentContext = false
    else if $scope.context == "set"
      constraints.module_id = $scope.module.id
      constraints.module_type = $scope.context
      $scope.orb_type = "facet"
      $scope.memoryBankModalOptions.module = $scope.module
      $scope.memoryBankModalOptions.module.module_type = $scope.module.context_type || constraints.module_type # needed to be v2/v3 compatible with knowledge bank
      $scope.memoryBankModalOptions.forceAssignmentContext = true # already starting in assignment scope, don't allow user to zoom out
    else if $scope.context == "series"
      constraints.module_id = $scope.module.id
      constraints.module_type = "seriesGoals" # for hitting the right API in MemoryStorage
      $scope.orb_type = "module"
      $scope.memoryBankModalOptions.module = $scope.module
      $scope.memoryBankModalOptions.module.module_type = $scope.module.context_type # needed to be v2 compatible with knowledge bank
      $scope.memoryBankModalOptions.forceAssignmentContext = true # already starting in assignment scope, don't allow user to zoom out
    else
      $scope.orb_type = "module"

    $scope.prepareMiniMemoryBank(constraints)
])
