angular.module('cerego.common').component("cgMilestone", {
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("milestone"),
  bindings: {
    data: '<'
  },

  controllerAs: '$milestoneCtrl',

  controller: ['$filter', '$scope', 'DebugHelper', 'LtiService', ($filter, $scope, DebugHelper, LtiService) ->
    DebugHelper.register("milestoneCtrl", $scope)

    this.setMilestoneNameAndType = () ->
      this.isBBC = LtiService.isBBC
      if this.isBBC
        slug = "bbc_" + this.data.attributes.slug
      else
        slug = this.data.attributes.slug
      this.name = $filter('translate')("js.milestones.names." + slug) # snake case fix
      this.milestoneType = this.data.attributes.slug.replace('_', '-')

    this.setProgress = () ->
      this.showProgressBar = this.data.attributes['reporting-type'] == 'percentage'
      if this.showProgressBar
        this.percentProgress = $filter('decimalToPercentage')(this.data.attributes['value']) # number for % reporting types
        this.completed = this.percentProgress == '100'
      else
        this.completed = this.data.attributes['value'] # true / false for boolean reporting types

      this.firstunlocked = this.data.attributes["first-time-unlocked"]


    this.$onInit = () ->
      this.setMilestoneNameAndType()
      this.setProgress()

    this # otherwise coffeescript will return the onInit function
  ]
})
