ceregoCommon.controller("memoryBankModal", ['$scope', 'DebugHelper', 'MemoryStorage',  ($scope, DebugHelper, MemoryStorage) ->
  DebugHelper.register("memoryBankModal", $scope)

  _.assignIn $scope,
    setContext: ->
      $scope.sessionContextId = $scope.module?.id ? $scope.course?.id
      $scope.sessionContextType = $scope.module?.type ? $scope.course?.type

    loadScopeFromModalOptions: ->
      $scope.user = this.modalOptions.user
      $scope.course = this.modalOptions.course
      $scope.module = this.modalOptions.module

  # listen for module being selected in the knowledge bank so we can change the scoping
  # for the learn button. If no module is selected (i.e. zoom out in knowledge bank), reset
  # to the default scope provided by modalOptions
  $scope.$on "memoryBank:setCurrentModule", (e, module) ->
    $scope.learnButtonLoaded = false
    if !module
      $scope.loadScopeFromModalOptions()
    else
      module.type = if module.module_type == 'set' then 'sets' else 'series'
      $scope.module = module
    $scope.setContext()
])
