ceregoCommon.factory('V3GroupsResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_groups_path(":partner_id")
      params:
        partner_id: "@partner_id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_group_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_group_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_groups_path(":partner_id")
      params:
        partner_id: "@partner_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_group_path(":id")
      params:
        id: "@id"
    bulk_destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_groups_path(":partner_id")
      params:
        partner_id: "@partner_id"
    smart_group_preview:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_smart_group_preview_path(":partner_id")
      params:
        partner_id: "@partner_id"
])
