ceregoCommon.factory('V3TransliterationDistractorsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_facet_transliteration_distractors_path(":facet_id")
      params:
        facet_id: "@facet_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_facet_transliteration_distractors_path(":facet_id")
      params:
        facet_id: "@facet_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_transliteration_distractor_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_transliteration_distractor_path(":id")
      params:
        id: "@id"
])
