ceregoCommon.service("V3PassageHighlightingService", [->
  service =
    insertBlankInPassage: (cloze) ->
      passageText = cloze.attributes['passage-text']
      clozeOffset = cloze.attributes.offset
      clozeLength = cloze.attributes.length

      beforeCloze = passageText.slice(0, clozeOffset)
      blanks = "______"
      afterCloze = passageText.slice(clozeOffset + clozeLength, passageText.length)

      beforeCloze + blanks + afterCloze

  service
])
