
ceregoCommon.component('cgDropdown',

  bindings:
    buttonOptions: "<"      # options for dropdown
    optionDisplayPath: "<?" # if buttonOptions is an array of objects instead of an array of strings, which attribute of the object to display.
                            # Can be a nested path that _.get understands
    currentSelection: "<?"  # initially the default selection, gets overridden by what the user selects
    onSelection: "&?"       # callback function for when a option is selected
    onSelectionArgs: "=?"   # arguments to pass to callback function in addition to the currentSelection

  # https://www.pivotaltracker.com/story/show/157864674
  # for some reason tests break if we don't use safe navigation ?. here, but not in other places with Packs
  # not worth fixing right now because we can get rid of Packs for good once everything is on webpack anyway
  templateUrl: Packs?.iKnowRoutes?.common_v3_templates_path("_dropdown")

  controller: ['$window', '$filter', '$scope', '$rootScope', 'DebugHelper', ($window, $filter, $scope, $rootScope, DebugHelper)  ->
    DebugHelper.register("cgDropdownCtrl", $scope)
    $ctrl = this

    $ctrl.dismissDropdownHandler = (event) ->
      $scope.$apply ->
        $ctrl.dropdown = {}

    # attach listener so user can click off dropdown to dismiss
    angular.element("body").click $ctrl.dismissDropdownHandler

    # make sure to unbind listener when this element is destroyed
    $scope.$on '$destroy', ->
      angular.element("body").unbind('click', $ctrl.dismissDropdownHandler)

    # listen for event broadcast by other dropdowns on the same page
    $scope.$on "buttonDropdown:dismissDropdown", (e, flag) ->
      $ctrl.dropdown = {}

    _.assignIn $ctrl, {
      dropdown: {}

      displayOption: (option) ->
        if $ctrl.optionDisplayPath
          # try to access path in option, if undefined, just return option (relevant when the default option is a string, but all the selectable options are objects)
          _.get(option, $ctrl.optionDisplayPath, option)
        else
          option

      selectOption: (option) ->
        $ctrl.currentSelection = option
        if $ctrl.onSelection
          params = {data: $ctrl.currentSelection}
          _.assignIn params, $ctrl.onSelectionArgs if $ctrl.onSelectionArgs
          $ctrl.onSelection(params)

      toggleShowDropdown: ($event) ->
        $event.preventDefault()
        $event.stopPropagation()

        $ctrl.currentSelection ||= $filter('translate')('js.common.dropdown.default')

        # save the old value of the dropdown flag before broadcasting an event to make all button dropdown instances
        # (including this one) clear their dropdown flag
        oldDropdownSetting = $ctrl.dropdown.showDropdown
        $rootScope.$broadcast "buttonDropdown:dismissDropdown", true
        $ctrl.dropdown.showDropdown = !oldDropdownSetting
    }

    # Warning, controllers must either return themselves or a non-object variable
    $ctrl
  ]
)
