ceregoCommon.factory('V3ItemsResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_item_path(":id")
      params:
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_item_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_set_items_path(":set_id")
      params:
        set_id: "@set_id"
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_set_items_path(":set_id")
      params:
        set_id: "@set_id"
    clone:
      method: 'POST'
      isArray: false
      url: APIRoutes.clone_v3_item_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_item_path(":id")
      params:
        id: "@id"
])
