ceregoCommon.factory('PartnerUserTagTypeResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_tag_types_path(":partner_id")
      params:
        partner_id: "@partner_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_tag_types_path(":partner_id")
      params:
        partner_id: "@partner_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_tag_type_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_tag_type_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
])
