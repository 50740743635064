ceregoCommon.factory('V3UsersResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_users_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_user_path(":id")
      params:
        id: "@id"
])

