ceregoCommon.directive("cgProgressCard", [ ->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("progress_card")
  scope:
    context: "="

  controller: ['$window', '$scope', '$location', '$filter', 'DebugHelper', 'AssignmentDetailsService', 'CourseContextService', 'GoalMathHelper', ($window, $scope, $location, $filter, DebugHelper, AssignmentDetailsService, CourseContextService, GoalMathHelper) ->
    DebugHelper.register("cgProgressCard", $scope)

    _.assignIn $scope, {
      uiState:
        loading: true
      #
      initialize: () ->
        if $scope.context == "course"
          CourseContextService.initialize().then (success) ->
            $scope.course = CourseContextService.course
            $scope.percent_progress = $filter('decimalToPercentage')($scope.course.meta.progress)
            $scope.uiState.loading = false
        else if $scope.context == "assignment"
          AssignmentDetailsService.initialize().then (success) ->
            $scope.assignment = AssignmentDetailsService.assignment
            if $scope.assignment.type == "sets"
              $scope.assignment.context_type = "set"
            else
              $scope.assignment.context_type = "series"
            $scope.percent_progress = parseInt($scope.assignment.meta?['memory-aggregate'].progress * 100) || 0
            if $scope.assignment.meta?['due-at']
              $scope.pastDue = (new Date() > new Date($scope.assignment.meta['due-at']))
            $scope.score = $filter('levelRoundDown')($scope.assignment.meta?['memory-aggregate'].score) || 0 # Should be rounded to a tenth
            $scope.hasLevelGoal = !_.isUndefined($scope.assignment.meta?['level-goal'])
            if $scope.hasLevelGoal
              $scope.level_goal = $filter('levelRoundDown')($scope.assignment.meta?['level-goal'])
            $scope.days_to_achieve = GoalMathHelper.levelToDaysConversion($scope.level_goal)
            $scope.uiState.loading = false
    }

    $scope.initialize()
  ]
])
