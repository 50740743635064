ceregoCommon.directive("cgLearnerDashboardCard", [ ->
  restrict: "E"
  scope:
    contextType: "="
    contextId: "="
    contextProgress: "=" # Check if this will be passed in or have study item return it
    contextLevel: "="
    nextStudyTime: "="
    firstTimeCompleted: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("learner_dashboard_card")

  controller: ['$window', '$scope', '$location', '$filter', 'DebugHelper', 'UserManager', 'StudyItemsService', 'timeHelper', 'GoalMathHelper', 'MySetsResource', 'ModalService', 'SessionSettings', 'AssignmentDetailsService', 'toastHelper', 'LtiService',
  ($window, $scope, $location, $filter, DebugHelper, UserManager, StudyItemsService, timeHelper, GoalMathHelper, MySetsResource, ModalService, SessionSettings, AssignmentDetailsService, toastHelper, LtiService) ->

    DebugHelper.register("cgLearnerDashboardCard", $scope)

    _.assignIn $scope, {
      AssignmentDetailsService: AssignmentDetailsService
      LtiService: LtiService
      SessionSettings: SessionSettings

      stateUI:
        loaded: false
        currentMode: 0

      FIRST_TIME_UNSTARTED: 1
      START_MORE_ITEMS: 2
      GOOD_FOR_NOW: 3
      FADING_ONLY: 4
      PROGRESS_COMPLETED: 5
      ONE_DAY_IN_MS: 24 * 60 * 60 * 1000

      launchGoodForNow: () ->
        version = AssignmentDetailsService.assignment.attributes["learn-version"]
        options = { sessionContextType: $scope.contextType, sessionContextId: $scope.contextId, returnUrl: SessionSettings.returnURL, studyItem: { version: version }}
        options.embed = true if SessionSettings.embed
        ModalService.showModal('good-for-now-modal', options)

      showGoMobile: () ->
        ModalService.showModal('mobile-app-modal', {modalType: 'transparent' })

      startLearning: () ->
        version = AssignmentDetailsService.assignment.attributes["learn-version"]
        if version == 4
#          TODO: pass temp_login_token if in LTI
          $window.location.href = $scope.stateUI.learnPath
        else
          $location.url($scope.stateUI.learnPath)

      continueLearning: () ->
        $scope.firstTimeCompleted = false
        $scope.initialize()

      toggleSchedule: () ->
        if AssignmentDetailsService.assignment.meta['in-schedule'] then $scope.unSchedule() else $scope.addToSchedule()

      confirmUnschedule: () ->
        MySetsResource.removeFromSchedule
          id: $scope.contextId
        , (success) ->
          AssignmentDetailsService.assignment.meta['in-schedule'] = false
          if LtiService.isBBC
            confirm_key = 'js.learn_app_dashboard.progress_completed.bbc_assignment_removed'
          else
            confirm_key = 'js.learn_app_dashboard.progress_completed.assignment_removed'
          toastHelper.showToast('simpleToast', message: $filter("translate")(confirm_key))

      unSchedule: () ->
        ModalService.showModal('unschedule-assignment-modal', {modalType: 'transparent', confirmUnschedule: $scope.confirmUnschedule, isBBC: LtiService.isBBC })

      addToSchedule: () ->
        MySetsResource.addToSchedule
          id: $scope.contextId
        , (success) ->
          AssignmentDetailsService.assignment.meta['in-schedule'] = true
          if LtiService.isBBC
            confirmation_key = 'js.learn_app_dashboard.progress_completed.bbc_assignment_added'
          else
            confirmation_key = 'js.learn_app_dashboard.progress_completed.assignment_added'
          toastHelper.showToast('simpleToast', message: $filter("translate")(confirmation_key))

      setNextRecommendedModule: () ->
        StudyItemsService.loadStudyItem(null, null, "recommended", SessionSettings.returnURL).then (studyItem) ->
          $scope.stateUI.nextModule = studyItem.learnPath

      initialize: () ->
        # 5 Progress is 100% & completed for the first time
        if $scope.contextProgress == 100 && $scope.firstTimeCompleted
          $scope.stateUI.currentMode = $scope.PROGRESS_COMPLETED
          if LtiService.isBBC
            title_key = 'js.learn_app_dashboard.progress_completed.bbc_title'
          else
            title_key = 'js.learn_app_dashboard.progress_completed.title'
          $scope.stateUI.title = $filter('translate')($filter('randomTranslateStringArray')(title_key))
          $scope.stateUI.loaded = true
        else
          StudyItemsService.loadStudyItem($scope.contextType, $scope.contextId, "recommended", SessionSettings.returnURL).then (studyItem) ->
            $scope.stateUI.unstartedCount = studyItem.unstarted
            $scope.stateUI.fadingCount = studyItem.fading
            $scope.stateUI.learnPath = studyItem.learnPath
            $scope.stateUI.durationString = timeHelper.secToString(studyItem.sessionLengthSec)

            # 1 First time ever
            # contextProgress is undefined with logged out study
            # studyItem.primaryCollection is undefined for sets with no assignment and it is good for now
            if ($scope.contextProgress == 0 || _.isUndefined($scope.contextProgress)) && studyItem.primaryCollection # undefined with logged out study
              title_key = 'js.learn_app_dashboard.first_time_unstarted.title'
              $scope.stateUI.currentMode = $scope.FIRST_TIME_UNSTARTED
              $scope.stateUI.totalMemoriesCount = studyItem.primaryCollection.attributes["studiable-memories-count"]
              if $scope.contextLevel
                $scope.stateUI.estimatedStudyTimeLower = GoalMathHelper.estimateRangeLower($scope.contextLevel)
                $scope.stateUI.estimatedStudyTimeUpper = GoalMathHelper.levelToDaysConversion($scope.contextLevel)
            # 2 Has anything unstarted
            else if studyItem.unstarted > 0
              $scope.stateUI.currentMode = $scope.START_MORE_ITEMS
              if LtiService.isBBC
                title_key = 'js.learn_app_dashboard.start_more_items.bbc_title'
              else
                title_key = 'js.learn_app_dashboard.start_more_items.title'
            # 3 No fading and no unstarted (good for now)
            else if studyItem.fading == 0 && studyItem.unstarted == 0
              $scope.setNextRecommendedModule()
              $scope.stateUI.currentMode = $scope.GOOD_FOR_NOW
              now = new Date
              # Set to 1 day if next study time is less than 1 day
              duration = Math.max(moment.duration($scope.nextStudyTime - now).asMilliseconds(), $scope.ONE_DAY_IN_MS)
              $scope.stateUI.duration = timeHelper.msecToString(duration)
              if LtiService.isBBC
                title_key = 'js.learn_app_dashboard.good_for_now.bbc_title'
              else
                title_key = 'js.learn_app_dashboard.good_for_now.title'
            # 4 No unstarted and there is fading.
            else if studyItem.fading > 0 && studyItem.unstarted == 0
              $scope.stateUI.currentMode = $scope.FADING_ONLY
              if LtiService.isBBC
                title_key = 'js.learn_app_dashboard.fading_only.bbc_title'
              else
                title_key = 'js.learn_app_dashboard.fading_only.title'
            # Should not be possible
            else
              DebugHelper.logError("cgLearnerDashboardCard unexpected value progress: #{progress}, unstarted: #{studyItem.unstarted}, fading: #{studyItem.fading}")

            if title_key
              $scope.stateUI.title = $filter('translate')($filter('randomTranslateStringArray')(title_key))

            $scope.stateUI.loaded = true
    }

    $scope.initialize()
  ]

])
