import * as angular from 'angular';

angular.module('cerego.common').factory('V3MyCoursesResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) =>
    $resource(
      '',
      {},
      {
        query: {
          method: 'GET',
          isArray: false,
          url: APIRoutes.v3_my_courses_path()
        }
      }
    )
]);
