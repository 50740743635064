ceregoCommon.factory('PartnerLibraryResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_partner_library_path(":partner_id")
      params:
        partner_id: "@partner_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_partner_partner_library_path(":partner_id")
      params:
        partner_id: "@partner_id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_partner_library_path(":partner_id")
      params:
        partner_id: "@partner_id"
])
