ceregoCommon.directive("cgSearchableItemsCollection", [ ->
  restrict: "E"
  scope:
    assignment: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("searchable_items_collection")

  controller: ['$window', '$scope', '$location', '$filter', '$analytics', 'DebugHelper', 'V3SetItemsResource', 'V3ModelHelper', ($window, $scope, $location, $filter, $analytics, DebugHelper, V3SetItemsResource, V3ModelHelper) ->
    DebugHelper.register("cgSearchableItemsCollection", $scope)

    _.assignIn $scope, {
      routes: Packs.iKnowRoutes
      searchOptions:
        query: ""

      loadItems: () ->
        V3SetItemsResource.query
          set_id: $scope.assignment.id
          include:  "image,facets.anchor,facets.association,facets.anchor.image,facets.association.image"
          'page[size]': 200
        , (success) ->
          $scope.items = V3ModelHelper.parse(success)
    }

    $scope.$watch "assignment", ->
      if $scope.assignment
        $scope.loadItems()
  ]
])
