ceregoCommon.directive("cgDailyPolarGraph", [ ->
  restrict: "E"
  scope:
    data: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("daily-polar-graph")

  controller: ['$window', '$scope', '$filter', '$location', 'DebugHelper', ($window, $scope, $filter, $location, DebugHelper) ->
    DebugHelper.register("cgDailyPolarGraph", $scope)

    INNER_RADIUS = 0
    DEFAULT_OUTER_RADIUS = 5
    TRANSITION_DURATION = 2000

    _.assignIn $scope, {
      stateUI:
        hourBegin: [0,3,6,9,12,15,18,21]
        classNames: ['twelve-am', 'three-am', 'six-am', 'nine-am', 'twelve-pm', 'three-pm', 'six-pm', 'nine-pm', 'twelve-am']
        loading: true
      tooltipDirection: ["top", "top", "bottom", "bottom", "bottom", "bottom", "top", "top"]
      arcs: d3.layout.pie()([1,1,1,1,1,1,1,1])
      maxRadius: 100
      maxSliceRadius: 95
      colorIntensity: [0,0,0,0,0,0,0,0]
      tooltips: []

      setMostEfficientDay: () ->
        $scope.maxEfficiency = _.max($scope.data.efficiency)
        $scope.mostEfficientIndex =_.indexOf($scope.data.efficiency, $scope.maxEfficiency)

      setUpGraph: () ->
        svg = d3.select("svg g")

        _.each(svg.selectAll("path")[0], (element, index) ->
          d3.select(element).on("mouseover", () ->
            angular.element(".v3-polar-tooltip-"+ index).addClass("visible")
          ).on("mouseout", () ->
            angular.element(".v3-polar-tooltip-"+ index).removeClass("visible")
          )
          $scope.tooltips[index] =
            heading: $filter('translate')('js.org_dash.hours.' + $scope.stateUI.hourBegin[index])
            caption: $filter('translate')('js.org_dash.daily_graph.tooltip')
            direction: $scope.tooltipDirection[index]
            classname: "v3-polar-tooltip-" + index
        )

      updateGraph: () ->
        if $scope.data.popularity
          $scope.setMostEfficientDay()
          $scope.stateUI.loading = false
          svg = d3.select("svg g")
          arc = d3.svg.arc().innerRadius(INNER_RADIUS).outerRadius(DEFAULT_OUTER_RADIUS)
          svg.selectAll("path").data($scope.arcs).attr("d", arc)

          _.each(svg.selectAll("path")[0], (element, index) ->
            maxPopularity = _.max($scope.data.popularity)
            if maxPopularity == 0
              radius = 0
            else
              radius = ($scope.data.popularity[index]/maxPopularity) * $scope.maxSliceRadius
            colorClass = "color-" + _.ceil($scope.data.efficiency[index] * 10)
            arc = d3.svg.arc().innerRadius(INNER_RADIUS).outerRadius(radius)

            unless d3.select(element).classed(colorClass)
              #d3 does not have "remove all classes" function yet
              $(element).removeAttr("class")
              d3.select(element).classed(colorClass, true)

            d3.select(element).transition().ease('elastic').duration(TRANSITION_DURATION).attr("d", arc)
          )
    }

    $scope.setUpGraph()
    $scope.$watch "data", (newVal, oldVal) ->
      $scope.updateGraph()
    , true
  ]

])
