/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.service("InstructorNamesHelper", ['$rootScope', '$filter', 'DebugHelper', function($rootScope, $filter, DebugHelper) {
  const $scope = $rootScope.$new();
  DebugHelper.register("InstructorNamesHelper", $scope);

  _.assignIn($scope, {
    getFormattedInstructorNames(instructor_names) {
      if (instructor_names.length === 1) {
        return instructor_names[0];
      } else if (instructor_names.length === 2) {
        return $filter('translate')("js.learn.courses.course_detail.two_instructors_string", {instructor_one: instructor_names[0], instructor_two: instructor_names[1]});
      } else if (instructor_names.length === 3) {
        return $filter('translate')("js.learn.courses.course_detail.three_instructors_string", {instructor_one: instructor_names[0], instructor_two: instructor_names[1], instructor_three: instructor_names[2]});
      } else if (instructor_names.length >= 4) {
        const extra_instructors_count = instructor_names.length - 3;
        return $filter('translate')("js.learn.courses.course_detail.many_instructors_string", {instructor_one: instructor_names[0], instructor_two: instructor_names[1], instructor_three: instructor_names[2], extra_instructors_count});
      } else {
        return $filter('translate')("js.learn.courses.course_detail.no_instructors_string");
      }
    }
  }
  );

  return $scope;
}
]);
