ceregoCommon.directive("cgWeeklyBarGraph", [ ->
  restrict: "E"
  scope:
    data: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("weekly-bar-graph")

  controller: ['$window', '$scope', '$filter', '$location', 'DebugHelper', ($window, $scope, $filter, $location, DebugHelper) ->
    DebugHelper.register("cgWeeklyBarGraph", $scope)

    ANIMATION_DURATION = 400

    _.assignIn $scope, {
      stateUI:
        daySlugs: [
          'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
        ]
      tooltips: []

      getPercentageFromPopularity: () ->
        max = _.max($scope.data.popularity)
        if max == 0
          $scope.data.popularity
        else
          _.map($scope.data.popularity, (value) ->
            _.ceil((value / max) * 100)
          )

      setMostEfficientDay: () ->
        $scope.maxEfficiency = _.max($scope.data.efficiency)
        $scope.mostEfficientIndex =_.indexOf($scope.data.efficiency, $scope.maxEfficiency)

      updateGraph: () ->
        if $scope.data.popularity
          $scope.setMostEfficientDay()
          percentageArray = $scope.getPercentageFromPopularity()
          elements = angular.element(".v3-bar-container .v3-bar").get()
          $scope.data.colorIntensity = [0,0,0,0,0,0,0]


          _.each(elements, (element, index) ->
            percentage = percentageArray[index]
            $scope.data.colorIntensity[index] = _.ceil($scope.data.efficiency[index] * 10)
            elm = angular.element(element)
            elm.css('height', 0)
            elm.animate({ height: percentage + '%'}, ANIMATION_DURATION)
            $scope.tooltips[index] =
              heading: $filter('translate')('js.org_dash.days.' + $scope.stateUI.daySlugs[index] + '.text')
              caption: $filter('translate')('js.org_dash.weekly_graph.tooltip')
          )
    }

    $scope.$watch "data", (newVal, oldVal) ->
      $scope.updateGraph()
    , true
  ]

])
