ceregoCommon.controller("instructionalItemViewerModal", ['$scope' , 'DebugHelper', 'SessionSettings', 'V3InstructionalPresentationsResource', ($scope, DebugHelper, SessionSettings, V3InstructionalPresentationsResource) ->

  DebugHelper.register("instructionalItemViewerModal", $scope)

  _.assignIn $scope,
    uiState: {}

    getCurrentTime: () ->
      now = new Date()
      now.toISOString()

    setUIState: ->
      pdfAnnotation = _.find($scope.item.relationships?.annotations.data, (annotation) ->
        annotation.relationships.document.data?.attributes['content-type'] == "application/pdf"
      )

      embedItemType = $scope.item.attributes["ic-subtype"] == "instructional_embed"

      if pdfAnnotation || embedItemType
        $scope.uiState.fullInstructionalEmbed = true

      if $scope.item?.meta['already-seen']
        $scope.uiState.alreadySeen = true

    markAsDone: () ->

      params = {}
      params.study_time_millis = $scope.getStudyDuration()
      params.session_guid = SessionSettings.studySessionId
      params.item_id = $scope.item.id
      params.occurred_at = $scope.getCurrentTime()

      V3InstructionalPresentationsResource.create params
      , (success) ->
        $scope.item.meta ||= {}
        $scope.item.meta['already-seen'] = true
        $scope.uiState.alreadySeen = true

    getStudyDuration: () ->
      $scope.endTime = Date.now()
      return $scope.endTime - $scope.startTime

    initialize: () ->
      $scope.item = $scope.modalOptions.item
      $scope.startTime = Date.now()
      $scope.setUIState()

  $scope.initialize()
])
