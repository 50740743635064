ceregoCommon.controller("courseInfoModal", ['$analytics', '$filter', '$q', '$scope' , 'DebugHelper', 'V3ModelHelper', 'InstructorNamesHelper', 'paginatedAssignmentsService', ($analytics, $filter, $q, $scope, DebugHelper, V3ModelHelper, InstructorNamesHelper, paginatedAssignmentsService) ->

  DebugHelper.register("courseInfoModal", $scope)

  _.assignIn $scope,
    paginatedAssignmentsService: paginatedAssignmentsService
    listTemplatePath: Packs.iKnowRoutes.common_v3_templates_path('_assignment_info_tile')
    loadingTemplatePath: Packs.iKnowRoutes.partners_v3_templates_path('_loading_tab_user_metrics')
    stateUI:
      activeAssignment: {}

    styles:
      fullRow: true
      showSearchInsideList: false

    tabs: [
      slug: "default"
    ]

    setCurrentAssignment: (assignment) ->
      $scope.stateUI.activeAssignment = assignment
      $analytics.eventTrack("self_enroll_browse_assignment")

    initialize: () ->
      $scope.course = $scope.modalOptions.course
      $scope.serviceOptions = { course_id: $scope.course.id }
      $scope.instructorNames = InstructorNamesHelper.getFormattedInstructorNames($scope.course.attributes["instructor-names"])
      $scope.headingText = $filter('translate')('js.learners.about_course_modal.assignment_num', { assignment_count: $scope.course.attributes["assignments-count"] })


  $scope.initialize()

])
