/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.service("SmartGroupRuleHelper", ['$rootScope', '$filter', 'DebugHelper', function($rootScope, $filter, DebugHelper) {
  const $scope = $rootScope.$new();
  DebugHelper.register("SmartGroupRuleHelper", $scope);

  _.assignIn($scope, {
    COMPARATORS: {
      EQUALS: "=",
      NOT_EQUALS: "!="
    },

    // Given a smart group, generates a descriptive string of the rules e.g. "Manager is George Michael, Contractor is Y"
    generateRuleString(group) {
      if (!group.attributes["rules-json"]) {
        return "";
      }

      const rules_json = JSON.parse(group.attributes["rules-json"]);
      const rule_strings = _.map(rules_json.AND, function(rule_json) {
        const comparator_symbol = _.keys(rule_json)[0];
        const translate_params = {
          label: rule_json[comparator_symbol].tag_type_label,
          value: rule_json[comparator_symbol].value
        };
        if (comparator_symbol === $scope.COMPARATORS.EQUALS) {
          return $filter("translate")("js.courses.add_group_modal.is", translate_params);
        } else if (comparator_symbol === $scope.COMPARATORS.NOT_EQUALS) {
          return $filter("translate")("js.courses.add_group_modal.is_not", translate_params);
        } else {
          // If this happens we are out of sync with backend
          return DebugHelper.logError(comparator_symbol, "unknown comparator");
        }
      });

      return _.join(rule_strings, ", ");
    },


    // Given a rules_json object, converts it into rules objects suitable for use with the cgSmartGroupRuleSelector component
    parseRulesJson(rules_json) {
      return _.map(rules_json.AND, function(rule_json) {
        const comparator_symbol = _.keys(rule_json)[0];
        const comparator_text = comparator_symbol === $scope.COMPARATORS.EQUALS ?
          $filter("translate")("js.partners.groups.rules.comparators.is")
        : comparator_symbol === $scope.COMPARATORS.NOT_EQUALS ?
          $filter("translate")("js.partners.groups.rules.comparators.is_not")
        :
          // If this happens we are out of sync with backend
          DebugHelper.logError(comparator_symbol, "unknown comparator");

        return {
          comparator : comparator_text,
          tag_type_id : rule_json[comparator_symbol].tag_type_id,
          tag_type_label : rule_json[comparator_symbol].tag_type_label,
          value : rule_json[comparator_symbol].value
        };
    });
    }
  });

  return $scope;
}
]);
