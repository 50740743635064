import * as angular from 'angular';

interface ILocalStorageTipCardScope extends ng.IScope {
  key: string;
  respawnMilliseconds: number;
}

angular.module('cerego.common').directive('localStorageTipCard', [
  'localStorageService',
  localStorageService => ({
    restrict: 'A',

    scope: {
      key: '@localStorageTipCard',
      respawnMilliseconds: '=respawnMilliseconds'
    },

    link: ($scope: ILocalStorageTipCardScope, element, attrs) => {
      const localStorageKey = `localStorageTipCard-${$scope.key}-lastShown`;
      const lastShown = localStorageService.get(localStorageKey) || 0;
      const timeSinceLastShown = new Date().getTime() - lastShown;
      if (timeSinceLastShown < $scope.respawnMilliseconds) {
        element.remove();
      }

      return element.find('.layout-message-card-close').on('click', () => {
        localStorageService.set(localStorageKey, new Date().getTime());
        element.remove();
      });
    }
  })
]);
