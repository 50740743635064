ceregoCommon.service('GroupContextService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', 'SiteNavigationService', 'V3GroupsResource', 'V3ModelHelper', (
    $rootScope, $routeParams, $q, DebugHelper, SiteNavigationService, V3GroupsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("GroupContextService", $scope)

  _.assignIn $scope,
    group: null
    deferred: null

    getGroupId: ->
      return $routeParams.group_id || $routeParams.id || -1

    initialize: (checkForRefresh) ->
      # if we already had a group loaded from before but then we're switching to another one
      if $scope.group && $scope.group.id != $scope.getGroupId()
        $scope.resetGroup()

      # if some other page marked the service dirty because it modified assignments/users counts
      if checkForRefresh && $scope.refreshRequired
        $scope.resetGroup()

      $scope.fetchGroup().then ->
        SiteNavigationService.setContext($scope.group, 'group')

    resetGroup: ->
      $scope.group = null
      $scope.deferred = null

    # call this function after modifying something that could affect
    # the counts attached to a group object (i.e. users)
    markRefreshRequired: ->
      $scope.refreshRequired = true
      $scope.childRefreshRequired = true

    removeChildRefreshRequired: ->
      $scope.childRefreshRequired = false

    fetchGroup: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        $scope.refreshRequired = false

        V3GroupsResource.get
          id: $scope.getGroupId()
        , (success) ->
          $scope.group = V3ModelHelper.parse(success)
          $scope.deferred.resolve($scope.group)
        , (error) ->
          DebugHelper.logError(error, "groupFetchError")
          $scope.deferred.reject(error)
      $scope.deferred.promise

  $scope
])
