ceregoCommon.directive("passwordInput", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("password_input")

  scope:
    ngModel: "="
    placeholder: "=?"
    minlength: "=?"
    hideIcon: "=?"

  controller: ['$scope', '$element', ($scope, $element) ->

    _.assignIn $scope,
      DEFAULT_PLACEHOLDER: "********"
      stateUI:
        passwordHidden: true
      toggleShowPassword: () ->
        $scope.stateUI.passwordHidden = !$scope.stateUI.passwordHidden
        if $scope.stateUI.passwordHidden
          $element.find("input")[0].type = "password"
        else
          $element.find("input")[0].type = "text"
      onPasswordChanged: () ->
        $scope.$emit("passwordChanged")
    this.$onInit = () ->
      $scope.minlength = $scope.minlength || USER_MIN_PASSWORD_LEN
      $scope.placeholder = $scope.placeholder || $scope.DEFAULT_PLACEHOLDER;

    this # otherwise coffeescript will return the onInit function
  ]
])
