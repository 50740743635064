import * as angular from 'angular';
import * as _ from 'lodash';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

interface ICtrl extends ng.IScope {
  stateUI: {
    showPostStudyCard: boolean;
  };
  postStudyCardHeaderMessage: string;
  postStudyCardDetailMessage: string;
}

angular.module('cerego.common').directive('cgAfterStudyMessageCard', [
  () => ({
    restrict: 'E',
    scope: {
      context: '=',
      contextId: '='
    },

    templateUrl: Packs.iKnowRoutes.common_v3_templates_path('after_study_message_card'),

    controller: [
      '$scope',
      '$routeParams',
      'DebugHelper',
      'V3AfterStudyMessagesResource',
      (
        $scope: ICtrl,
        $routeParams: ng.route.IRouteParamsService,
        DebugHelper: IDebugHelper,
        V3AfterStudyMessagesResource
      ) => {
        DebugHelper.register('cgAfterStudyMessageCard', $scope);

        $scope.stateUI = {
          showPostStudyCard: false
        };

        if (($routeParams.newMemoryCount > 0 || $routeParams.fadingMemoryCount > 0) && $routeParams['setIds[]']) {
          const params = {
            set_ids: _.castArray($routeParams['setIds[]']),
            new_memory_count: $routeParams.newMemoryCount,
            fading_memory_count: $routeParams.fadingMemoryCount
          };
          V3AfterStudyMessagesResource.create(params, success => {
            $scope.stateUI.showPostStudyCard = true;
            $scope.postStudyCardHeaderMessage = success.header;
            $scope.postStudyCardDetailMessage = success.message;
          });
        }
      }
    ]
  })
]);
