angular.module('cerego.common').component('cgProgressBar', {
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("progress_bar"),
  bindings: {
    faded: '=?',
    percentProgress: '=',
    styleType:'='
  },
  controllerAs: '$progressBarCtrl',
  controller: ['$scope', 'DebugHelper', '$interval', ($scope, DebugHelper, $interval) ->
    DebugHelper.register('progressBarCtrl', $scope)

    $ctrl = this

    _.assignIn $ctrl, {

      countProgress: () ->
        if $ctrl.stateUI.progress < $ctrl.percentProgress
          $ctrl.stateUI.progress = $ctrl.stateUI.progress + 1
        else
          $interval.cancel($ctrl.progressCounter)

      $onInit: () ->
        $ctrl.stateUI = {}
        $ctrl.stateUI.progress = 0
        $ctrl.progressCounter = $interval $ctrl.countProgress, 30
    }

    $ctrl
  ]
})
