ceregoCommon.service('paginatedInstructionalItemsService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', '$filter', 'V3CourseItemsResource', 'V3SetItemsResource', 'V3SeriesItemsResource', 'V3ModelHelper', ($rootScope, $routeParams, $q, DebugHelper, $filter, V3CourseItemsResource, V3SetItemsResource, V3SeriesItemsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("paginatedInstructionalItemsService", $scope)

  _.assignIn $scope,
    resources: {}
    activeTab: "default"
    defaultOptions:
      sort: ""
      'filter[type][]': ["InstructionalContent"]
      'page[size]': 200
    defaultResourceImage: '<%= image_path("v3/assignment_placeholder_60.png") %>'
    lastOptions: {}


    getPaginatedResults: (options = {}) ->
      options = _.defaults(options, $scope.defaultOptions)
      options.sort = ""
      deferred = $q.defer()

      if options['course_id']
        resource = V3CourseItemsResource
      else if options['set_id']
        resource = V3SetItemsResource
      else if options['series_id']
        resource = V3SeriesItemsResource

      resource.query options
      , (success) ->
        $scope.loading = false

        instructional_items = V3ModelHelper.parse(success, includeMeta: true)
        if options['course_id']
          _.each(instructional_items.data, (item) ->
            item.show_path = Packs.iKnowRoutes.v3_learn_course_instructional_item_path(options['course_id'], item.id)
          )

        deferred.resolve(instructional_items)
      deferred.promise
  $scope
])
