declare const APIRoutes: v3.service.AppRoutes;

angular.module('cerego.common').factory('V3CourseItemsResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) =>
    $resource(
      '',
      {},
      {
        query: {
          method: 'GET',
          isArray: false,
          url: APIRoutes.v3_course_items_path(':course_id'),
          params: {
            course_id: '@course_id'
          }
        }
      }
    )
]);
