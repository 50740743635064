ceregoCommon.directive("cgItemCard", [ ->
  restrict: "E"
  scope:
    index: '@'
    item: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("item_card")

  controller: ['$scope', '$filter', '$element', '$routeParams', 'V3ContentService', 'V3ItemValidatorService', 'DebugHelper'
    ($scope, $filter, $element, $routeParams, V3ContentService, V3ItemValidatorService, DebugHelper) ->

      DebugHelper.register("cgItemCard", $scope)

      _.assignIn $scope, {
        stateUI:
          showPostStudyCard: false

        V3ContentService: V3ContentService
        V3ItemValidatorService: V3ItemValidatorService

        initialize: () ->
          index = parseInt($scope.index)
          if index < 100
            $scope.stateUI.indexFontSize = "font-small"
          else if index < 1000
            $scope.stateUI.indexFontSize = "font-tiny"
          else
            $scope.stateUI.indexFontSize = "font-extratiny"

          $scope.item.card = $element[0]
          $scope.item.card.scrollIntoView() if $scope.item.id == parseInt($routeParams.id)

        filterText: (text) ->
          text = $filter('stripHTML')(text)
          if text.length > 30
            text.slice(0, 27) + '...'
          else
            text

        getFacetQuizzes: (facet) ->
          quizzes = {}
          for quiz in facet.attributes['quiz-settings'].enabled
            # TODO: remove extra check here after we drop MC from Q&A
            if (quiz.match('multiple_choice') && !$scope.item.attributes['template-type'] != "question_and_answer")
              quizzes.multiple_choice = (quizzes.multiple_choice || 0) + 1
            else if quiz.match('fill_in_the_blank')
              quizzes.fill_in_the_blank = (quizzes.fill_in_the_blank || 0) + 1
            else if quiz.match('click_in_the_dark')
              quizzes.click_in_the_dark = (quizzes.click_in_the_dark || 0) + 1
            else if quiz.match('application_question_select')
              quizzes.application_question_select = (quizzes.application_question_select || 0) + 1
            else if quiz.match('question_and_answer_select')
              quizzes.multiple_choice = (quizzes.multiple_choice || 0) + 1
            else if quiz.match('sequence_injection')
              quizzes.sequence_injection = (quizzes.sequence_injection || 0) + 1
          quizzes

        getFacetNum: () ->
          totalFacets = _.filter($scope.item.relationships.facets?.data || [], (facet) -> facet.relationships.association?.data).length
          $scope.getFacetName(totalFacets,true)

        getQuizNum: () ->
          totalQuizzes = _.reduce($scope.item.relationships.facets?.data || [], (sum, facet) ->
            sum + facet.attributes['quiz-settings'].enabled.length
          , 0)
          suffix = if totalQuizzes == 1 then ".one" else ".other"
          $filter('translate')("js.item_editor.item_cards.quiz" + suffix, count: totalQuizzes)

        getFacetName: (count, with_count) ->
          slug = "js.item_editor.item_cards.#{$scope.item.attributes['template-type']}.facet_name"
          if with_count
            slug += "_with_count"
          slug += if count == 1 then ".one" else ".other"
          $filter("translate")(slug, count: count)

        getFacetNameForIndex: (index) ->
          if $scope.item.relationships.facets.data.length == 1
            $scope.getFacetName(1, false)
          else
            "#{$scope.getFacetName(1, false)} #{index}"

        getTemplateType: () ->
          if $scope.item.attributes['template-type'] == "instructional_contents"
            if $scope.item.attributes['ic-subtype']
              $scope.item.attributes['ic-subtype']
            else
              "instructional_contents_page"
          else
            $scope.item.attributes['template-type']

        getIncompleteName: () ->
          if $scope.item.attributes['template-type']
            $filter('translate')("js.item_editor.item_cards.#{$scope.getTemplateType()}.incomplete")
          else
            $filter('translate')("js.item_editor.item_cards.incomplete_item")

        getMissingAssociationName: () ->
          $filter('translate')("js.item_editor.item_cards.#{$scope.item.attributes['template-type']}.missing_association")

        getMissingAnchorName: () ->
          $filter('translate')("js.item_editor.item_cards.#{$scope.getTemplateType()}.missing_anchor")

        getItemTypeName: () ->
          $filter('translate')("js.item_editor.item_cards.#{$scope.getTemplateType()}.new")

        hasAssociation: () ->
          $scope.item.relationships.facets?.data[0]?.relationships.association?.data || ($scope.item.attributes['template-type']== "instructional_contents" && $scope.item.relationships.annotations?.data[0])

        hasAnchor: () ->
          $scope.item.relationships.facets?.data[0]?.relationships.anchor?.data || $scope.item.attributes['title']

        canShowFacets: () ->
          !_.includes(['instructional_contents', 'application_questions', 'question_and_answers', 'patterns'], $scope.item.attributes['template-type'])
      }

      $scope.initialize()
  ]
])
