ceregoCommon.service("UserCsvUploader", ['DebugHelper', '$rootScope', '$q', '$timeout', 'UserCsvUploadsResource', (DebugHelper, $rootScope, $q, $timeout, UserCsvUploadsResource) ->
  $scope = $rootScope.$new()

  DebugHelper.register("UserCsvUploader", $scope)

  _.assignIn $scope,
    parse: (user_csv_upload_id, params) ->
      if params.dry_run
        finished_state = "parse_completed"
      else
        finished_state = "persist_completed"

      deferred = $q.defer()

      onSuccess = (success) ->
        csvUpload = success.data
        if csvUpload.attributes.state == finished_state
          deferred.resolve(csvUpload)
        else if csvUpload.attributes.state == "error"
          deferred.reject()
        else
          deferred.notify(csvUpload)
          $timeout ->
            UserCsvUploadsResource.get
              id: user_csv_upload_id
            , onSuccess
            , deferred.reject
          , 2000

      UserCsvUploadsResource.parse_background { user_csv_upload_id: user_csv_upload_id },
        params
      , onSuccess
      , deferred.reject

      deferred.promise
])
