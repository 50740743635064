import * as angular from 'angular';

export interface IMyEmailResource {
  send_token: ng.resource.IResourceMethod<v3.resource.IEmail>;
  verify_token: ng.resource.IResourceMethod<v3.resource.IEmail>;
}

angular.module('cerego.common').factory('V3MyEmailResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) =>
    $resource(
      '',
      {},
      {
        send_token: {
          method: 'POST',
          isArray: false,
          url: APIRoutes.send_confirmation_token_v3_my_email_path(':id'),
          params: {
            id: '@id'
          }
        },
        verify_token: {
          method: 'POST',
          isArray: false,
          url: APIRoutes.verify_confirmation_token_v3_my_email_path(':id'),
          params: {
            id: '@id'
          }
        }
      }
    )
]);
