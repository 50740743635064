ceregoCommon.factory('V3ConceptDistractorsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_concept_distractors_path(":concept_id")
      params:
        concept_id: "@concept_id"
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_concept_path(":id")
      params:
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_concept_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_concept_distractors_path(":concept_id")
      params:
        concept_id: "@concept_id"

])
