ceregoCommon.factory('V3CourseAssignmentsResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_assignments_path(":course_id")
      params:
        course_id: "@course_id"
    bulk_update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_course_assignments_path(":course_id")
      params:
        course_id: "@course_id"
    bulk_destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_course_assignments_path(":course_id")
      params:
        course_id: "@course_id"
])
