ceregoCommon.factory('PartnerUserTagResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_user_tag_type_partner_user_tags_path(":partner_user_tag_type_id")
      params:
        partner_user_tag_type_id: @partner_user_tag_type_id
    show:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_tag_type_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_partner_user_tag_path(":id")
      params:
        id: "@id"
    remove:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_user_tag_path(":id")
      params:
        id: "@id"

])
