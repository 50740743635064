import defaultAssignmentIcon from 'images/v3/common/assignment-card-icon.svg'

ceregoCommon.directive("cgGradient", [ ->
  restrict: 'E'
  scope:
    leftColor: "="
    rightColor: "="
    percent: "="

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("cgGradient", $scope)

    _.assignIn $scope, {
      setGradient: () ->
        if $scope.percent == 100
          $scope.element.css('background', "")
          $scope.element.css('background-color', $scope.leftColor)
        else
          color_percent = 110 - $scope.percent
          gradient_values = "left, " + $scope.leftColor + " , " + $scope.rightColor + " " + color_percent + "%"
          # see: https://stackoverflow.com/questions/29999097/jquery-adding-multiple-css-properties-with-same-name-doesnt-work
          css = [
            "background-image: -ms-linear-gradient(" + gradient_values + ")",
            "background-image: -o-linear-gradient(" + gradient_values + ")",
            "background-image: -moz-linear-gradient(" + gradient_values + ")",
            "background-image: -webkit-linear-gradient(" + gradient_values + ")",
            "background-image: linear-gradient(" + gradient_values + ")",
          ].join(";")
          $scope.element.attr('style', css)
    }
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element

    $scope.$watch "percent", ->
      $scope.setGradient() if _.isNumber($scope.percent)
])

ceregoCommon.directive("cgAssignmentCard", [ ->
  restrict: "E"
  scope:
    assignment: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("assignment_card")

  controller: ['$window', '$scope', '$location', 'DebugHelper', 'MemoryBankLevelsService', 'setLearnURLService', ($window, $scope, $location, DebugHelper, MemoryBankLevelsService, setLearnURLService) ->
    DebugHelper.register("cgAssignmentCard", $scope)

    _.assignIn $scope, {
      assignmentDetailsPath: ""
      assignmentLearnPath: ""
      Math: $window.Math
      levelValue:
        levelNew: MemoryBankLevelsService.levels[1].intLevel
        levelBuilding: MemoryBankLevelsService.levels[2].intLevel
        levelOne: MemoryBankLevelsService.levels[3].intLevel
        levelTwo: MemoryBankLevelsService.levels[4].intLevel
        levelThree: MemoryBankLevelsService.levels[5].intLevel
        levelFour: MemoryBankLevelsService.levels[6].intLevel
        levelMastery: MemoryBankLevelsService.levels[7].intLevel
      defaultIconImage: defaultAssignmentIcon
      locale: angular.element("body").data("locale") || "en"
      uiState: {}

      setBackgroundImageGradient: () ->
        score = parseFloat($scope.assignment.meta["memory-aggregate"]?['score'])
        if score > $scope.levelValue.levelNew && score < $scope.levelValue.levelBuilding
          $scope.leftColor = "#333333" #gray6
          $scope.rightColor = "#333333" #gray6
        else if score >= $scope.levelValue.levelBuilding && score < $scope.levelValue.levelOne
          $scope.leftColor = "#333333" #gray6
          $scope.rightColor = "#2294f2" #level_1
        else if score >= $scope.levelValue.levelOne && score < $scope.levelValue.levelTwo
          $scope.leftColor = "#2294f2" #level_1
          $scope.rightColor = "#9347BF" #$purple
        else if score >= $scope.levelValue.levelTwo && score < $scope.levelValue.levelThree
          $scope.leftColor = "#9347BF" #$purple
          $scope.rightColor = "#E7446F" #$pink
        else if score >= $scope.levelValue.levelThree && score < $scope.levelValue.levelFour
          $scope.leftColor = "#E7446F" #$pink
          $scope.rightColor = "#EEB100" #$yellow
        else if score >= $scope.levelValue.levelFour && score < $scope.levelValue.levelMastery
          $scope.leftColor = "#EEB100" #$yellow
          $scope.rightColor = "#7AB72D" #$light-green
        else
          $scope.leftColor = $scope.rightColor = "#7AB72D" #$light-green

        if  (score == Math.floor(score)) || (score <= $scope.levelValue.levelBuilding)
          $scope.percent = 100
        else if score > $scope.levelValue.levelBuilding && score < $scope.levelValue.levelOne
          $scope.percent = ((score - $scope.levelValue.levelBuilding)/ ($scope.levelValue.levelOne - $scope.levelValue.levelBuilding)) * 100
        else
          $scope.percent = ((score - Math.floor(score)) * 100)

      setNumberItemsToStudy: () ->
        unstarted      = $scope.assignment.meta['memory-aggregate']?['unstarted-items-count'] ? 0
        fading         = $scope.assignment.meta['memory-aggregate']?['eligible-items-count'] ? 0
        studyType      = CeregoSharedLib.studyTypes.RECOMMENDED
        started        = $scope.assignment.meta['memory-aggregate']?['studied-items-count'] ? 0
        percentStarted = started / Math.max(started + unstarted, 1)
        progress       = $scope.assignment.meta['memory-aggregate']?['progress'] ? 0
        $scope.sessionSizes = CeregoSharedLib.sessionSizeParts(unstarted, fading, studyType, percentStarted, progress)
        $scope.fading = $scope.sessionSizes.fading
        $scope.unstarted = $scope.sessionSizes.unstarted
        $scope.itemsToLearn = Math.min($scope.assignment.meta["memory-aggregate"]?["unstarted-items-count"] + $scope.assignment.meta["memory-aggregate"]?["eligible-items-count"], 10)
        $scope.fadingItems = Math.min($scope.assignment.meta["memory-aggregate"]?["eligible-items-count"], 15)
        $scope.progress = progress
        if $scope.assignment.meta['due-at']
          $scope.pastDue = (new Date() > new Date($scope.assignment.meta['due-at']))

      isGoodForNow: () ->
        $scope.unstarted == 0 && $scope.fading == 0
    }

    $scope.$watch "assignment.meta['memory-aggregate']['score']", ->
      $scope.setBackgroundImageGradient()

    $scope.$watch "assignment.meta['memory-aggregate']['unstarted-items-count']", ->
      $scope.setNumberItemsToStudy()

    $scope.$watch "assignment.meta['memory-aggregate']['eligible-items-count']", ->
      $scope.setNumberItemsToStudy()

    $scope.$watch "assignment", ->
      assignment = $scope.assignment
      if assignment
        $scope.uiState.isScorm = !!assignment.attributes['scorm-package-id']
        $scope.uiState.isAssessment = assignment.attributes['goal-type'] == 'assessment'
        $scope.uiState.isSurvey = assignment.attributes['goal-type'] == 'survey'
        if assignment.module_type == "set" || assignment.type == "sets"
          if assignment.attributes['learn-version'] != 4
            $scope.assignmentDetailsPath = Packs.iKnowRoutes.v3_learn_set_path(assignment.id)
            $scope.assignmentLearnPath = Packs.iKnowRoutes.quiz_v2_set_learn_path(assignment.id)
          else
            studyType = if $scope.isGoodForNow() then 'force_review' else 'recommended'
            $scope.assignmentLearnPath = setLearnURLService.getUrl({ id: parseInt(assignment.id), version: 4 }, { studyType: studyType })
            fromPath = setLearnURLService.getReturnUrl($window.location.pathname, { fromEmbed: $scope.fromEmbed })
            learnPath = new URL("#{$window.location.origin}#{$scope.assignmentLearnPath}")
            learnPath.searchParams.append('returnUrl', fromPath)
            $scope.assignmentDetailsPath = learnPath.toString()
        else
          $scope.assignmentDetailsPath = Packs.iKnowRoutes.v3_learn_series_path(assignment.id)
          $scope.assignmentLearnPath = Packs.iKnowRoutes.quiz_v2_series_learn_path(assignment.id)
        $scope.setNumberItemsToStudy()
  ]

  link: ($scope, element, attrs) ->
    $scope.uiState.isScorm = !!$scope.assignment.attributes['scorm-package-id']
])
