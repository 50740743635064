import * as angular from 'angular';

export interface IActivationTokensResource extends ng.resource.IResourceClass<v3.resource.IUser> {}

angular.module('cerego.common').factory('V3ActivationTokensResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) => {
    return $resource(
      '',
      {},
      {
        get: {
          method: 'GET',
          isArray: false,
          url: APIRoutes.v3_activation_token_path(':activation_token'),
          params: {
            activation_token: '@activation_token'
          }
        }
      }
    );
  }
]);
