ceregoCommon.directive("goalTriangle", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("goal_triangle")

  scope:
    levelValue: "="
    goalReached: "="
    size: "="

  controller: [ '$scope', ($scope) ->
    _.assignIn $scope, {

      getLevelClass: () ->
        "level-" + Math.floor(parseFloat($scope.levelValue))
    }
  ]
])
