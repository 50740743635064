ceregoCommon.factory('V3AnnotationsResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_annotation_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_item_annotations_path(":item_id")
      params:
        item_id: "@item_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_annotation_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_annotation_path(":id")
      params:
        id: "@id"
])
