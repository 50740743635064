import * as angular from 'angular';
import 'es6-promise/auto'; // Move this to a cerego common if we start using Promise objects elsewhere
import * as _ from 'lodash';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

interface ITip {
  id: string;
  attributes: {
    slug: string;
  };
  meta: object;
}

interface ITipJsonApiDocument {
  data: ITip[];
}

interface ITipServiceScope extends ng.IScope {
  tipPromises: { [category: string]: Promise<ITipJsonApiDocument> };
  tips: { [category: string]: ITip[] };
  load: (object) => Promise<ITip[]>;
  dismiss: (tipId: string, category: string) => void;
}

angular.module('cerego.common').service('tipService', [
  'TipsResource',
  '$q',
  '$rootScope',
  'DebugHelper',
  (TipsResource, $q: ng.IQService, $rootScope: ng.IRootScopeService, DebugHelper: IDebugHelper) => {
    const $scope = $rootScope.$new() as ITipServiceScope;
    DebugHelper.register('tipService', $scope);

    return _.assignIn($scope, {
      tipPromises: {},
      tips: {},

      load: async (options: object): Promise<ITip[]> => {
        const category: string = options['filter[category]'];
        if (!$scope.tipPromises[category]) {
          $scope.tipPromises[category] = TipsResource.index(options).$promise;
        }
        const tipsResponse = await $scope.tipPromises[category];
        $scope.tips[category] = tipsResponse.data;
        return tipsResponse.data;
      },

      dismiss: async (tipId: string, category: string) => {
        const dismissStatus = await TipsResource.update({ tip_id: tipId }, { status: 'dismissed' }).$promise;
        _.remove($scope.tips[category], { id: tipId });
      }
    });
  }
]);

interface ITipCardScope extends ng.IScope {
  tipSlug: string;
  tipCategory: string;
  tip: { id: string; meta: object }; // Make this more specific to the shape of meta
}

angular.module('cerego.common').directive('tipCard', [
  'tipService',
  (tipService: ITipServiceScope) => ({
    restrict: 'A',

    scope: {
      tipSlug: '@tipCard',
      tipCategory: '@tipCategory'
    },

    controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) => DebugHelper.register('tipCard', $scope)],

    // Temporarily give element the 'any' type, because it believes element is JQLite when it's really jQuery
    link: ($scope: ITipCardScope, element: any, attrs) => {
      element.hide();
      const options = {
        'filter[category]': $scope.tipCategory
      };
      tipService.load(options).then(tips => {
        $scope.tip = _.find(tips, tip => tip.attributes.slug === $scope.tipSlug);
        if ($scope.tip && !$scope.tip.meta['is-dismissed']) {
          element.show();
        }
      });

      return angular.element('.layout-message-card-close').on('click', () => {
        element.remove();
        tipService.dismiss($scope.tip.id, $scope.tipCategory);
      });
    }
  })
]);
