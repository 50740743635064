ceregoCommon.component('cgButtonDropdown',
  transclude:
    'buttonSlot': 'buttonContent'
    'dropdownMenuSlot': 'menuContent'

  bindings:
    dropdownDisabled: '<?'
    buttonStyle: '@'

  # https://www.pivotaltracker.com/story/show/157864674
  # for some reason tests break if we don't use safe navigation ?. here, but not in other places with Packs
  # not worth fixing right now because we can get rid of Packs for good once everything is on webpack anyway
  templateUrl: Packs?.iKnowRoutes?.common_v3_templates_path("_button_dropdown")

  controller: ['$window', '$scope', '$rootScope', 'DebugHelper', ($window, $scope, $rootScope, DebugHelper) ->
    DebugHelper.register("cgButtonDropdownCtrl", $scope)
    $ctrl = this

    $ctrl.dismissDropdownHandler = (event) ->
      $scope.$apply ->
        $ctrl.dropdown = {}

    # attach listener so user can click off dropdown to dismiss
    angular.element("body").click $ctrl.dismissDropdownHandler

    # make sure to unbind listener when this element is destroyed
    $scope.$on '$destroy', ->
      angular.element("body").unbind('click', $ctrl.dismissDropdownHandler)

    # listen for event broadcast by other dropdowns on the same page
    $scope.$on "buttonDropdown:dismissDropdown", (e, flag) ->
      $ctrl.dropdown = {}

    _.assignIn $ctrl, {
      dropdown: {}

      toggleShowDropdown: ($event) ->
        unless $ctrl.dropdownDisabled
          $event.preventDefault()
          $event.stopPropagation()

          # save the old value of the dropdown flag before broadcasting an event to make all button dropdown instances
          # (including this one) clear their dropdown flag
          oldDropdownSetting = $ctrl.dropdown.showDropdown
          $rootScope.$broadcast "buttonDropdown:dismissDropdown", true
          $ctrl.dropdown.showDropdown = !oldDropdownSetting
    }

    # Warning, controllers must either return themselves or a non-object variable
    $ctrl
  ]
)
