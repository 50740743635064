ceregoCommon.factory('V3PasswordsResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_password_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_passwords_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_password_path(":id")
      params:
        id: "@id"
])
