import assignmentPlaceholderImage from 'images/v3/assignment_placeholder_60.png'

ceregoCommon.filter('defaultImage', [->
  (image, type) ->
    image.data?.attributes?.url || assignmentPlaceholderImage
])

ceregoCommon.filter('V3filterItemsText', ['$filter', ($filter) ->
  (input, text) ->
    check = (field) ->
      !_.isEmpty(field) && $filter('filter')([field.toLowerCase()], text.toLowerCase()).length > 0

    if _.isEmpty(text)
      input
    else
      _.filter(input, (item) ->
        check(item.attributes?.title) ||
          _.some(item.relationships?.facets?.data, (facet_data) ->
            check(facet_data.attributes['association-label']) || check(facet_data.relationships?.anchor?.data?.attributes?['text-html']) ||
            check(facet_data.relationships?.association?.data?.attributes?['text-html'])
          )
        )
])

ceregoCommon.filter('dateInTimezone', ['$filter', ($filter) ->
  (date) ->
    $filter('date')(new Date(date))
])
