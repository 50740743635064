ceregoCommon.directive('cgTooltip', ['$window', '$timeout', '$compile', ($window, $timeout, $compile) ->
  restrict: "A"

  transclude:
    'textSlot'    : '?tooltipText'
    'tooltipSlot': 'tooltipContent'

  templateUrl: Packs?.iKnowRoutes?.common_v3_templates_path("_tooltip_content")

  scope:
    direction: "@direction"
    toggle: "@toggle"
    size: "@size"

  controller: ['$window', '$scope', '$timeout', 'DebugHelper', ($window, $scope, $timeout, DebugHelper)  ->
    DebugHelper.register("cgToolTipCtrl", $scope)

    $scope.handleClick = (e) ->
      e.stopPropagation()

      $scope.element.toggleClass('visible')
      $scope.toggled = !$scope.toggled

      # add listener to body so that when tooltip is toggled, clicking anywhere on body detoggles it
      if $scope.toggled
        $timeout ->
          $("body").click $scope.handleClick
      else
        $("body").unbind('click', $scope.handleClick)
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
    $scope.element.addClass("v3-tooltip-container")

    if $scope.toggle == 'click'
      $scope.element.click $scope.handleClick
    else
      $scope.element.hover ->
        $(this).toggleClass('visible')
])
