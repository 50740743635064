ceregoCommon.controller("addUsersModal", ['$analytics','$scope', '$rootScope', '$q', 'DebugHelper', 'V3CourseUsersResource', 'PartnerUsersResource', 'CourseContextService', 'paginatedLearnersService', 'V3ModelHelper', 'myPartnersService', 'toastHelper', '$filter', 'AmplitudeService', (
  $analytics, $scope, $rootScope, $q, DebugHelper, V3CourseUsersResource, PartnerUsersResource, CourseContextService, paginatedLearnersService, V3ModelHelper, myPartnersService, toastHelper, $filter, AmplitudeService) ->
  DebugHelper.register("addUsers", $scope)

  _.assignIn $scope,
    step: "input"
    options:
      send_email: true
    optionsToAdd:
      addByEmail:
        slug: 'add_by_email'
        name: "js.partners.add_users_modal.add_options.by_email"
      addByMemberId:
        slug: 'add_by_member_id'
        name: "js.partners.add_users_modal.add_options.by_member_id"
    uiState:
      showSendEmailOption: false # whether to show the option to send an email to invited users or not
      showInviteOptions: false   # whether to show the option to add by email or member id
      loading: false
    input:
      invite_list: ""
      error_invites: []
      success_list: []

    selectAddMethod: (choice) ->
      $scope.addMethod = choice
      $scope.step = 'input'
      $scope.setShowSendEmailOption()

    toggleOptions: (option) ->
      $scope.options[option] = !$scope.options[option]

    addUsers: (is_instructor) ->
      $scope.uiState.loading = true
      userCreationPromises = _.map $scope.input.invite_list.split(","), (obj) ->
        # is_instructor isn't used by PartnerUsersResource, but we can leave it here because it gets filtered server side
        params = { is_instructor: is_instructor, send_email_invite: is_instructor || $scope.options.send_email, enrolled_via: "enrolled_via_direct_invite" }

        obj = obj.trim()
        if $scope.addMethod.slug == 'add_by_email'
          params.email = obj
        else
          params.member_id = obj
          params.include = "user-partner-id"

        $scope.userResourceCreate(params).$promise

      objectName = if $scope.addMethod.slug == 'add_by_email' then 'email' else 'member_id'

      $rootScope.$broadcast "searchableList:markDirty"
      $q.allSettled(userCreationPromises).then (createResponses) ->
        [successResponses, errorResponses] = _.partition createResponses, (response) ->
          response.state == "fulfilled"
        $scope.step = "confirm"
        $scope.uiState.loading = false
        $scope.input.invite_list = ""
        $scope.input.success_list = _.map successResponses, (res) ->
          if $scope.addMethod.slug == 'add_by_email'
            res.value.data.attributes.email
          else
            result = V3ModelHelper.parse(res.value)
            result.relationships['user-partner-id'].data.attributes['member-id']
        # TODO how to know the error was from invalid email? for now assume every error means invalid email
        $scope.input.error_invites = _.map errorResponses, (res) ->
          hash = {}
          hash.correct = false
          hash[objectName] = res.reason.config.data[objectName]
          hash
        $scope.trackAddUsersToCourse(CourseContextService.course.id, $scope.addMethod.slug, $scope.input.success_list.length)
        CourseContextService.markRefreshRequired()
        $rootScope.$broadcast "searchableList:reloadResources"
        toastHelper.showToast('simpleToast', message: $filter("translate")("js.toast." + $scope.modalOptions.user_type + "_added", count: successResponses.length))

    addSingleUser: (invite, is_instructor) ->
      $scope.uiState.loading = true
      params = { is_instructor: is_instructor, send_email_invite: is_instructor || $scope.options.send_email }

      if $scope.addMethod.slug == 'add_by_email' then params.email = invite.email else params.member_id = invite.member_id

      $rootScope.$broadcast "searchableList:markDirty"
      $scope.userResourceCreate params, (success) ->
        $scope.uiState.loading = false
        invite.correct = true
        $scope.trackAddUsersToCourse(CourseContextService.course.id, $scope.addMethod.slug, $scope.input, 1)
        CourseContextService.markRefreshRequired()
        $rootScope.$broadcast "searchableList:reloadResources"
        singular_user_type = $scope.modalOptions.user_type.slice(0, -1)
        toastHelper.showToast('simpleToast', message: $filter("translate")("js.toast." + singular_user_type + "_added"))
      , (failure) ->
        $scope.uiState.loading = false

    # Track adding users to a course
    trackAddUsersToCourse: (courseId, addMethod, userCount) ->
      return if $scope.modalOptions.user_type == "partner_users"
      eventAction = "Users Added Method - " + addMethod
      $analytics.eventTrack(eventAction, { category: "AddUsersToCourse", label: courseId, value: userCount })
      AmplitudeService.logEvent('invite_users_to_course', { course_id: courseId })

    setShowSendEmailOption: () ->
      if $scope.addMethod.slug != 'add_by_email' || $scope.modalOptions.user_type == 'instructors' || $scope.modalOptions.user_type == "partner_users"
        $scope.uiState.showSendEmailOption = false
      else if $scope.modalOptions.user_type == 'learners'
        CourseContextService.initialize().then ->
          if CourseContextService.course?.attributes?.state == "published"
            $scope.uiState.showSendEmailOption = true
          if CourseContextService.course?.attributes?.state == "unpublished"
            $scope.options.send_email = false
            $scope.uiState.showSendEmailOption = false

    initialize: () ->
      $scope.addMethod = $scope.optionsToAdd.addByEmail
      $scope.setShowSendEmailOption()

      # PartnerUsersResource.create and V3CourseUsersResource.create have different interfaces, but if we partially apply the
      # first argument (used for building the route), the resulting function has the same interface
      if $scope.modalOptions.user_type == "partner_users"
        $scope.userResourceCreate = _.curry(PartnerUsersResource.create, 2)({partner_id: myPartnersService.currentProductPartner.id})
      else # learner and instructor both use course user
        CourseContextService.initialize().then ->
          $scope.userResourceCreate = _.curry(V3CourseUsersResource.create, 2)({course_id: CourseContextService.course.id})

      if myPartnersService.currentProductPartner?.attributes["partner-settings"]?["member-id-enabled"] && $scope.modalOptions.user_type != 'partner_users'
        $scope.uiState.showInviteOptions = true

  $scope.initialize()
])
